import React, { Component } from 'react';

import { CancelDialog } from './components/СancelDialog';
import { EditDialog } from './components/EditDialog';

import { DIALOG_TYPES } from '../../bi/constants/serviceType';

import ChatService from '../../bi/services/chat';
import WorkspaceService from '../../bi/services/workspace';
import OrderService from '../../bi/services/order';
import { ITripItem, IAdditionalService } from '../../bi/types/trips';

interface ITripDetailsDialogProps {
  multipleTickets: boolean,
  ticketsWithSamePNR: any[],
  nameTrip: string,
  isTrain: boolean,
  isAir: boolean,
  isAeroexpress: boolean,
  isHotel: boolean,
  isTaxiVoucher: boolean,
  isVipHall: boolean,
  isTransfer: boolean,
  isChatLoading: boolean,
  chatService: ChatService,
  workspaceService: WorkspaceService,
  orderService: OrderService,
  item: ITripItem,
  onCloseDialog: () => void,
  onRefreshTrain: () => void,
  onSubmitCancellationModal: (result?: any, item?: any, inputValue?: string, serviceType?: string) => void,
  onCancellationBack?: () => void,
  onRequestAutomaticCancellation?: () => void,
  onAutomaticCancellation?: () => void,
  onCancelAutomaticCancellation?: () => void,
  voidTimeLimit?: any | null,
  modalType: string,
  orderItems: any,
  showElem: boolean,
  setShowElem: (value: boolean) => void,
  additionalServiceTrip: IAdditionalService | null,
}

class TripDetailsDialog extends Component<ITripDetailsDialogProps> {
  isUserDisabled = () => {
    const { workspaceService, chatService } = this.props;
    const { Email } = workspaceService.get();

    return chatService.hasDisabledUser(Email) || !workspaceService.canUseIntercom;
  };

  renderCancelModal = () => {
    const {
      ticketsWithSamePNR,
      multipleTickets,
      nameTrip,
      isTrain,
      isAir,
      isAeroexpress,
      isHotel,
      isTaxiVoucher,
      isVipHall,
      isChatLoading,
      orderService,
      chatService,
      item,
      onCloseDialog,
      onRefreshTrain,
      onSubmitCancellationModal,
      onCancellationBack,
      onRequestAutomaticCancellation,
      onAutomaticCancellation,
      onCancelAutomaticCancellation,
      voidTimeLimit,
      isTransfer,
      showElem,
      setShowElem,
      additionalServiceTrip,
    } = this.props;

    const isDisabled = this.isUserDisabled();

    return (
      <CancelDialog
        ticketsWithSamePNR={ ticketsWithSamePNR }
        multipleTickets={ multipleTickets }
        nameTrip={ nameTrip }
        isTrain={ isTrain }
        additionalServiceTrip={ additionalServiceTrip }
        isAir={ isAir }
        isAeroexpress={ isAeroexpress }
        isHotel={ isHotel }
        isTaxiVoucher={ isTaxiVoucher }
        isVipHall={ isVipHall }
        isChatLoading={ isChatLoading }
        orderService={ orderService }
        chatService={ chatService }
        item={ item }
        onCloseDialog={ onCloseDialog }
        onRefreshTrain={ onRefreshTrain }
        onSubmitCancellationModal={ onSubmitCancellationModal }
        onCancellationBack={ onCancellationBack }
        onRequestAutomaticCancellation={ onRequestAutomaticCancellation }
        onAutomaticCancellation={ onAutomaticCancellation }
        onCancelAutomaticCancellation={ onCancelAutomaticCancellation }
        voidTimeLimit={ voidTimeLimit }
        isDisabled={ isDisabled }
        isTransfer={ isTransfer }
        isCalculation={ false }
        showElem={ showElem }
        setShowElem={ setShowElem }
      />
    );
  };

  renderCalculationCancelModal = () => {
    const {
      ticketsWithSamePNR,
      multipleTickets,
      nameTrip,
      isTrain,
      isAir,
      isAeroexpress,
      isHotel,
      isTaxiVoucher,
      isVipHall,
      isChatLoading,
      orderService,
      chatService,
      item,
      onCloseDialog,
      onRefreshTrain,
      onSubmitCancellationModal,
      onCancellationBack,
      onRequestAutomaticCancellation,
      onAutomaticCancellation,
      onCancelAutomaticCancellation,
      voidTimeLimit,
      isTransfer,
      showElem,
      setShowElem,
    } = this.props;

    const isDisabled = this.isUserDisabled();

    return (
      <CancelDialog
        ticketsWithSamePNR={ ticketsWithSamePNR }
        multipleTickets={ multipleTickets }
        nameTrip={ nameTrip }
        isTrain={ isTrain }
        isAir={ isAir }
        isAeroexpress={ isAeroexpress }
        isHotel={ isHotel }
        isTaxiVoucher={ isTaxiVoucher }
        isVipHall={ isVipHall }
        isChatLoading={ isChatLoading }
        orderService={ orderService }
        chatService={ chatService }
        item={ item }
        onCloseDialog={ onCloseDialog }
        onRefreshTrain={ onRefreshTrain }
        onSubmitCancellationModal={ onSubmitCancellationModal }
        onCancellationBack={ onCancellationBack }
        onRequestAutomaticCancellation={ onRequestAutomaticCancellation }
        onAutomaticCancellation={ onAutomaticCancellation }
        onCancelAutomaticCancellation={ onCancelAutomaticCancellation }
        voidTimeLimit={ voidTimeLimit }
        isDisabled={ isDisabled }
        isTransfer={ isTransfer }
        isCalculation
        showElem={ showElem }
        setShowElem={ setShowElem }
      />
    );
  };

  renderEditDialog = () => {
    const {
      nameTrip,
      isChatLoading,
      item,
      onCloseDialog,
      onSubmitCancellationModal,
      orderItems,
    } = this.props;

    const isDisabled = this.isUserDisabled();

    return (
      <EditDialog
        orderItems={ orderItems }
        nameTrip={ nameTrip }
        isChatLoading={ isChatLoading }
        item={ item }
        onCloseDialog={ onCloseDialog }
        onSubmitCancellationModal={ onSubmitCancellationModal }
        isDisabled={ isDisabled }
      />
    );
  };

  render() {
    const { modalType } = this.props;
    const dialogsRenderFuncs = {
      [DIALOG_TYPES.CANCEL]: this.renderCancelModal,
      [DIALOG_TYPES.EDIT]: this.renderEditDialog,
      [DIALOG_TYPES.CALCULATION_CANCEL]: this.renderCalculationCancelModal,
    };

    const dialogRenderFn = dialogsRenderFuncs[modalType];

    if (!dialogRenderFn) {
      return null;
    }

    return dialogRenderFn();
  }
}

export default TripDetailsDialog;
