import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconButton, Text, Tooltip, Select, Dropdown, Button, DotLoading } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import MainAnalytic from '../../../../bi/utils/analytics/main';
import { getVoidUntilEndOfDayString } from '../../../../bi/utils/airline';
import { hasDayCome, momentObject, prepareRealCheckoutDate } from '../../../../bi/utils/formatDate';
import parseJsonString from '../../../../bi/utils/parseJsonString';
import { canCancelledTrip, isActiveTrip, isServiceTrip } from '../../../../bi/utils/tripStatus';
import { isCheckAfterDeparture, isTicketChangeable } from '../../../../bi/utils/changeAirTrip';
import { isSmartAgent } from '../../../../bi/utils/env';
import getAccountId from '../../../../bi/utils/getAccountId';
import { CompanySetting } from '../../../../bi/utils/accountSettings';

import { ANALYTIC_SERVICE_TYPE_MATCH, SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRIPSTATUS, VERSIONSTATUS } from '../../../../bi/constants/trips';
import ROUTES from '../../../../bi/constants/routes';
import { AEROEXPRESS_TARIFF_TYPES } from '../../../../bi/services/aeroexpress/const';
import { AIRLINE_PROVIDERS } from '../../../../bi/constants/airline';
import { ADDITIONAL_MAIN, ADDITIONAL_TYPES } from '../../../../bi/constants/airlineAdditionalServices';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { ACCOUNT_IM } from '../../../../bi/constants/accounts';

import Link from '../../../Link';

import { ITripItem } from '../../../../bi/types/trips';
import PopupsService from '../../../../bi/services/popups';
import { Rights } from '../../../../bi/types/workspace';
import { IAccountSettingsProps } from '../../../../bi/services/accountSettings/types';
import { IAccountTravelPolicy } from '../../../../bi/services/workspace/types';

import styles from './index.module.css';

export const createLabels = (t: TFunction) => ({
  EDIT_ORDER: t('tripItem.orderHeader.editOrder'),
  REQUEST_REFUND: t('tripItem.orderHeader.requestRefund'),
  AIR_EDIT_ORDER: t('tripItem.orderHeader.airEditOrder'),
  REQUEST_WAS_SENT: t('tripItem.orderHeader.requestWasSent'),
  ADDITIONAL_ORDER: t('tripItem.orderHeader.additionalOrder'),
  CANCEL_ORDER: t('tripItem.orderHeader.cancelOrder'),
  AIR_CANCEL_ORDER: t('tripItem.orderHeader.airCancelOrder'),
  AIR_CALCULATION_CANCEL_ORDER: t('tripItem.orderHeader.airCalculationCancelOrder'),
  REFERENCE: t('tripItem.orderHeader.reference'),
  FREE_CANCELLATION: t('tripItem.orderHeader.freeCancellation'),
  DELETE: t('tripItem.orderHeader.cancelVoucher'),
  REQUEST: t('tripItem.orderHeader.request'),
  REQUEST_NUMBER: t('requestHeader.requestNumberText'),
  ENTER_REQUEST_NUMBER: t('tripItem.orderHeader.enterRequestNumber'),
  NO_CHANGE_OR_RETURN_TRIP: t('tripItem.orderHeader.noChangeOrReturnTrip'),
  TOOLTIP: {
    COMPLETED: t('tripItem.orderHeader.tooltip.completed'),
    UNCOMPLETED: t('tripItem.orderHeader.tooltip.uncompleted'),
    UNCOMPLETED_FREE: t('tripItem.orderHeader.tooltip.uncompletedFree'),
    DEMO: t('tripItem.orderHeader.tooltip.demo'),
    DEMO_SMARTAGENT: t('tripItem.orderHeader.tooltip.demoSmartagent'),
    AIR: {
      WARNING_FEE: t('tripItem.orderHeader.tooltip.air.warningFee'),
      NO_EDIT: t('tripItem.orderHeader.tooltip.air.noEdit'),
    },
    TRAIN: {
      EDIT: t('tripItem.orderHeader.tooltip.train.edit'),
      CANCEL: t('tripItem.orderHeader.tooltip.train.cancel'),
    },
    AEROEXPRESS: {
      DEFAULT: {
        EDIT: t('tripItem.orderHeader.tooltip.aeroexpress.default.edit'),
        CANCEL: t('tripItem.orderHeader.tooltip.aeroexpress.default.cancel'),
      },
      BUSINESS: {
        EDIT: {
          DEFAULT: t('tripItem.orderHeader.tooltip.aeroexpress.business.edit.default'),
          THREE_HOURS: t('tripItem.orderHeader.tooltip.aeroexpress.business.edit.threeHours'),
        },
        CANCEL: t('tripItem.orderHeader.tooltip.aeroexpress.business.cancel'),
      },
    },
    HIDE_ADDITIONAL_ORDER: t('tripItem.orderHeader.tooltip.hideAdditionalOrder'),
  },
  ADDITIONAL_SERVICES: {
    CHOOSE_SEAT: t('additionalServices.services.chooseSeat'),
    EXTRA_BAGGAGE: t('additionalServices.services.extraBaggage'),
    VIP_HALL: t('additionalServices.services.vipHall'),
    ESCORT: t('additionalServices.services.escort'),
    FLIGHT_CERTIFICATE: t('additionalServices.services.flightCertificate'),
  },
});

const APPROVE_SCHEME = {
  ONLY_AFTER_APPROVE: 'OnlyAfterApprove',
  AFTER_APPROVE: 'AfterApprove',
};

interface IOrderHeaderProps {
  item: ITripItem,
  tripId: number,
  tripName: string,
  messageSent: any,
  hasDisabledUser: boolean,
  airTicketHasCertificate: boolean,
  canBuyPersonalUnlimited: boolean,
  getSalary1C: boolean,
  isAdmin: boolean,
  removeFlightCertificate: boolean | undefined | CompanySetting,
  status: string,
  onEditService(): void,
  onCancelService(): void,
  onCalculationCancelService(): void,
  popupsService: PopupsService,
  getNoChangeOrReturnTrip: boolean,
  tripsRequests: any[],
  onOpenApproveDialog(Id: number, value: number | string): void,
  rights: Rights,
  isFreeFlightCertificate: boolean,
  accessFlightCertificates: boolean,
  travelPolicy: IAccountTravelPolicy | null,
  accountSettings: IAccountSettingsProps,
  showElem: boolean,
  itemData: any,
}

const OrderHeader = ({
  item: {
    Id,
    RequestId,
    ActualVersion: {
      JsonData,
      ServiceType,
      CheckoutDate,
      Status,
      Employees,
    },
  },
  hasDisabledUser,
  onCancelService,
  onCalculationCancelService,
  onEditService,
  getNoChangeOrReturnTrip,
  canBuyPersonalUnlimited,
  isAdmin,
  rights: {
    BuyTripAccount,
    BuyTripPersonal,
    EmployeeId,
  },
  travelPolicy,
  accountSettings: {
    searchSettings,
    hideAdditionalOrders,
  },
  tripId,
  status,
  messageSent: { cancel, edit, refund },
  tripsRequests,
  onOpenApproveDialog,
  getSalary1C,
  popupsService,
  airTicketHasCertificate,
  isFreeFlightCertificate,
  removeFlightCertificate,
  accessFlightCertificates,
  tripName,
  showElem,
  itemData = null,
}: IOrderHeaderProps) => {
  const { t } = useTranslation('components');
  const LABELS = useMemo(() => createLabels(t), [t]);
  const {
    workspaceService: {
      canBuyAccount,
      canBuyPersonal,
    },
  } = useServices(['Workspace']);
  const history = useHistory();

  const getSomeForHeader = () => {
    const checkAccess = (getNoChangeOrReturnTrip && !(isAdmin || canBuyPersonalUnlimited)) ? LABELS.NO_CHANGE_OR_RETURN_TRIP : '';

    let onEditServiceFn = onEditService;
    let onCancelServiceFn = onCancelService;
    let onCalculationCancelServiceFn = onCalculationCancelService;
    let tooltipTextEdit = checkAccess;
    let tooltipTextCancel = checkAccess;

    switch (ServiceType) {
      case SERVICETYPE.TRAIN: {
        tooltipTextEdit = LABELS.TOOLTIP.TRAIN.EDIT;
        onEditServiceFn = () => {};

        const dataSource = parseJsonString(JsonData);

        if (dataSource && dataSource.NonRefundable) {
          tooltipTextCancel = LABELS.TOOLTIP.TRAIN.CANCEL;
          onCancelServiceFn = () => {};
          onCalculationCancelServiceFn = () => {};
        }

        break;
      }
      // лютая дичь
      case SERVICETYPE.TRANSFER: {
        if (hasDisabledUser) {
          tooltipTextEdit = isSmartAgent ? LABELS.TOOLTIP.DEMO_SMARTAGENT : LABELS.TOOLTIP.DEMO;
          onEditServiceFn = () => {};
        }

        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const dataSource = parseJsonString(JsonData);

        if (!dataSource) {
          break;
        }

        switch (dataSource.Trip.TariffType) {
          case AEROEXPRESS_TARIFF_TYPES.BUSINESS: {
            tooltipTextEdit = LABELS.TOOLTIP.AEROEXPRESS.BUSINESS.EDIT.DEFAULT;
            onEditServiceFn = () => {};

            if (new Date(dataSource.CheckinDate).getTime() - Date.now() <= 1000 * 60 * 60 * 3) {
              tooltipTextCancel = LABELS.TOOLTIP.AEROEXPRESS.BUSINESS.CANCEL;
              onCancelServiceFn = () => {};
              onCalculationCancelServiceFn = () => {};

              tooltipTextEdit = LABELS.TOOLTIP.AEROEXPRESS.BUSINESS.EDIT.THREE_HOURS;
              onEditServiceFn = () => {};
            }

            break;
          }

          default: {
            tooltipTextEdit = LABELS.TOOLTIP.AEROEXPRESS.DEFAULT.EDIT;
            onEditServiceFn = () => {};

            if (hasDayCome(dataSource.CheckinDate)) {
              tooltipTextCancel = LABELS.TOOLTIP.AEROEXPRESS.DEFAULT.CANCEL;
              onCancelServiceFn = () => {};
              onCalculationCancelServiceFn = () => {};
            }

            break;
          }
        }
        break;
      }
    }

    return {
      tooltipTextEdit,
      tooltipTextCancel,
      onEditServiceFn,
      onCancelServiceFn,
      onCalculationCancelServiceFn,
    };
  };

  const handleClickOnTrip = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.RETURN_TICKET_PRESSED, {
      id: tripId,
      status,
      date: CheckoutDate,
      name: tripName,
    });

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_PRESSED(tripId, ANALYTIC_SERVICE_TYPE_MATCH[ServiceType]),
    );

    history.push({
      pathname: ROUTES.CHANGE_AIR_TRIP.SELECT_CHANGE(tripId, Id),
    });
  };

  const handleClickOnAdditional = () => {
    const {
      store: {
        vipHallPopup,
      },
    } = popupsService;

    const analyticType = vipHallPopup.show ?
      MainAnalytic.ACTIONS.VIP_HALL_BANNERS.AIRTRIPDETAIL_LEARNINGPOPUP_CLOSED :
      MainAnalytic.ACTIONS.VIP_HALL.AIRTRIPDETAIL_ADDSERVICES_OPENED;

    MainAnalytic.sendFirebase(analyticType);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_ADDITIONAL_SERVICES_PRESSED(
        tripId, ANALYTIC_SERVICE_TYPE_MATCH[ServiceType],
      ),
    );

    history.push({
      pathname: ROUTES.ADDITIONAL_SERVICES.MAIN(tripId, Id),
    });
  };

  const send1CRequestClickAmp = () => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_DETAIL_APPLICATION_NUMBER_PRESSED(ANALYTIC_SERVICE_TYPE_MATCH[ServiceType]),
    );
  };

  const checkTravelPolicy = () => {
    if (BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unlimited && Employees[0].Id === EmployeeId) {
      // @ts-ignore
      return !travelPolicy || !travelPolicy?.VipHallRule?.AllowBook;
    }

    return false;
  };

  const renderTooltipContent = (label: string) => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.content }
    >
      { label }
    </Text>
  );

  const renderVoidInfo = () => {
    if (ServiceType !== SERVICETYPE.AIR || Status === VERSIONSTATUS.CANCELLED) {
      return null;
    }

    const jsonData = parseJsonString(JsonData);

    if (!jsonData) {
      return null;
    }

    const { TicketsExtended = [{ VoidTimeLimitUTC: null }] } = jsonData;
    const { VoidTimeLimitUTC } = TicketsExtended[0];

    if (!VoidTimeLimitUTC) {
      return null;
    }

    const voidTimeLimit = getVoidUntilEndOfDayString(VoidTimeLimitUTC);

    const hideCancelledTrip = jsonData?.ProviderName === AIRLINE_PROVIDERS.AVIACENTR;

    if (!voidTimeLimit || hideCancelledTrip) {
      return null;
    }

    return (
      <Text type='NORMAL_18'>
        {LABELS.FREE_CANCELLATION}&nbsp;{voidTimeLimit}
      </Text>
    );
  };

  const renderHeaderCancelledLabels = (
    cancelLabel: string,
    calculationCancelLabel: string,
    tooltipText: string,
    isActiveLabel: boolean,
    onCancelServiceFn: () => void,
    onCalculationCancelServiceFn: () => void,
    isCancelledTrip = false,
  ) => {
    const isAir = ServiceType === SERVICETYPE.AIR;
    const isTrain = ServiceType === SERVICETYPE.TRAIN;
    const isAeroExpress = ServiceType === SERVICETYPE.AEROEXPRESS;
    const checkBuyTripAccount = BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unlimited && getNoChangeOrReturnTrip;

    const isDisabled = !!tooltipText || !!isActiveLabel;
    const iconType = isCancelledTrip ? 'cancel' : 'menuTrips';
    const iconColor = isCancelledTrip ? 'default' : 'blue';
    const handleClickCancel = isActiveLabel ? () => {} : onCancelServiceFn;
    const handleClickCalculationCancel = isActiveLabel ? () => {} : onCalculationCancelServiceFn;

    const isDisabledTripAccount = isDisabled || (BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unlimited && getNoChangeOrReturnTrip);

    const labelHtml = (
      <IconButton
        disabled={ isDisabled }
        iconType={ iconType }
        alternativeDesign={ isSmartAgent }
        iconColor={ iconColor }
        onClick={ handleClickCancel }
        qaAttr={ QA_ATTRIBUTES.trips.trip.cancelOrder }
      >
        <Text type='NORMAL_14' >{ cancelLabel }</Text>
      </IconButton>
    );

    const cancelButton = (
      <Tooltip
        renderContent={ () => (
          <Text
            color='white'
            className={ styles.tooltip_cancel }
          >
            { LABELS.NO_CHANGE_OR_RETURN_TRIP }
          </Text>
        ) }
      >
        <IconButton
          disabled={ isDisabledTripAccount }
          iconType={ iconType }
          alternativeDesign={ isSmartAgent }
          iconColor={ iconColor }
        >
          <Text type='NORMAL_14' >{ cancelLabel }</Text>
        </IconButton>
      </Tooltip>
    );

    const renderDropdown = () => {
      const accountId = getAccountId();

      if (ServiceType === SERVICETYPE.TRAIN && !itemData.TicketId && accountId === ACCOUNT_IM) return null;

      return (
        <Dropdown
          renderLabel={ () => (
            <IconButton
              disabled={ isDisabledTripAccount }
              iconType={ iconType }
              alternativeDesign={ isSmartAgent }
              iconColor={ iconColor }
            >
              <Text type='NORMAL_14' >{ cancelLabel }</Text>
            </IconButton>
          ) }
          isOpenMenu
          disabled={ isDisabledTripAccount }
          hoverOpenMenu
        >
          <div className={ styles.content }>
            <Button
              className={ styles.subitem }
              type='textual'
              onClick={ handleClickCancel }
            >
              { cancelLabel }
            </Button>
          </div>
          <div className={ styles.content }>
            <Button
              className={ styles.subitem }
              type='textual'
              onClick={ handleClickCalculationCancel }
            >
              { calculationCancelLabel }
            </Button>
          </div>
        </Dropdown>
      );
    };

    const dropdownHtml = showElem === false ? <DotLoading/> : renderDropdown();

    const contentHtml = isAir || isTrain || isAeroExpress ? dropdownHtml : labelHtml;

    const renderContentHtml = checkBuyTripAccount ? cancelButton : contentHtml;

    if (!tooltipText) {
      return (
        <div className={ styles.label }>
          { renderContentHtml }
        </div>
      );
    }

    return (
      <Tooltip
        className={ styles.tooltip_wrapper }
        renderContent={ () => renderTooltipContent(tooltipText) }
      >
        { contentHtml }
      </Tooltip>
    );
  };

  const getIconColor = (isCancelledTrip: boolean) => {
    if (isCancelledTrip) return 'default';

    if (isSmartAgent) return 'green';

    return 'blue';
  };

  const renderHeaderEditLabels = (
    label: string,
    tooltipText: string,
    isActiveLabel: string,
    onCallback: () => void,
    isCancelledTrip = false) => {
    const isDisabled = !!tooltipText || !!isActiveLabel;
    const iconType = isCancelledTrip ? 'cancel' : 'menuTrips';
    const handleClick = isActiveLabel ? () => {} : onCallback;

    const labelHtml = (
      <IconButton
        disabled={ isDisabled }
        iconType={ iconType }
        alternativeDesign={ isSmartAgent }
        iconColor={ getIconColor(isCancelledTrip) }
        onClick={ handleClick }
      >
        <Text type='NORMAL_14' >{ label }</Text>
      </IconButton>
    );

    if (!tooltipText) {
      return (
        <div className={ styles.label }>
          { labelHtml }
        </div>
      );
    }

    return (
      <Tooltip
        className={ styles.tooltip_wrapper }
        renderContent={ () => renderTooltipContent(tooltipText) }
      >
        { labelHtml }
      </Tooltip>
    );
  };

  const renderAdditionalItem = (value: keyof typeof ADDITIONAL_MAIN | keyof typeof ADDITIONAL_TYPES) => (
    <Text key={ value } type='NORMAL_14_130' color='white'>
      - {LABELS.ADDITIONAL_SERVICES[value]}
    </Text>
  );

  const renderAdditionalItems = () => {
    if (status !== TRIPSTATUS.BOOKED) {
      return null;
    }

    const personalBuy = BuyTripPersonal === BUYTRIPSPERSONALRIGHT.OnlyAfterApprove
      || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme;

    const renderItems = (obj: { [key: string]:string }) => Object.keys(obj).map((item) =>
      renderAdditionalItem(item as keyof typeof ADDITIONAL_MAIN | keyof typeof ADDITIONAL_TYPES));

    return personalBuy || checkTravelPolicy() || !searchSettings.includes(SERVICETYPE.VIP_HALL)
      ? renderItems(ADDITIONAL_MAIN)
      : renderItems(ADDITIONAL_TYPES);
  };

  const renderFlightCertificeteTooltip = () => !isSmartAgent && (
    <Text type='NORMAL_14_130' color='white'>
      - { LABELS.ADDITIONAL_SERVICES.FLIGHT_CERTIFICATE }
    </Text>
  );

  const renderTooltip = () => (
    <div className={ styles.tooltip_content }>
      {renderAdditionalItems()}
      { renderFlightCertificeteTooltip() }
    </div>
  );

  const renderOutTooltip = () => (
    <div className={ styles.tooltip_content_add }>
      <Text
        type='NORMAL_14'
        color='white'
      >
        { LABELS.TOOLTIP.HIDE_ADDITIONAL_ORDER }
      </Text>
    </div>
  );

  const renderHeaderAdditionalServices = () => {
    const styleWrap = [styles.tooltip_wrap];

    const renderAdditionalTooltip = hideAdditionalOrders ? renderOutTooltip : renderTooltip;

    return (
      <Tooltip
        className={ styleWrap.join(' ') }
        renderContent={ renderAdditionalTooltip }
      >
        <IconButton
          className={ styles.label }
          iconType='plusRound'
          alternativeDesign={ isSmartAgent }
          iconColor='blue'
          onClick={ handleClickOnAdditional }
          disabled={ hideAdditionalOrders }
        >
          <Text type='NORMAL_14'>
            { LABELS.ADDITIONAL_ORDER }
          </Text>
        </IconButton>
      </Tooltip>
    );
  };

  const renderHeaderItemEditAir = (
    label: string,
    showAirEditCondition: boolean,
    showCheckAfterDeparture: boolean,
  ) => {
    const checkBuyTripAccount = BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unlimited && getNoChangeOrReturnTrip;
    const isDisabled = !showAirEditCondition || checkBuyTripAccount;

    const labelWarning = checkBuyTripAccount ? LABELS.NO_CHANGE_OR_RETURN_TRIP : LABELS.TOOLTIP.AIR.WARNING_FEE;
    const tooltipLabel = isDisabled ? LABELS.TOOLTIP.AIR.NO_EDIT : labelWarning;

    const iconColor = isSmartAgent ? 'green' : 'blue';

    const labelHtml = (
      <IconButton
        disabled={ isDisabled }
        alternativeDesign={ isSmartAgent }
        iconType='menuTrips'
        iconColor={ iconColor }
        onClick={ handleClickOnTrip }
      >
        <Text type='NORMAL_14' >{ label }</Text>
      </IconButton>
    );

    if (!showCheckAfterDeparture) {
      return null;
    }

    return (
      <Tooltip
        className={ styles.tooltip_wrapper }
        renderContent={ () => renderTooltipContent(tooltipLabel) }
      >
        { labelHtml }
      </Tooltip>
    );
  };

  const renderDeleteButton = (tooltipText: string, onClick: () => void) => {
    const { Voucher: { CurrentAmount, Status: StatusVoucher } } = parseJsonString(JsonData);

    const disabled = CurrentAmount <= 0 || StatusVoucher === 2 || !!tooltipText;

    const htmItem = (
      <IconButton
        iconType='trashcan'
        iconColor='blue'
        onClick={ onClick }
        disabled={ disabled }
      >
        <Text type='NORMAL_14'>
          {LABELS.DELETE}
        </Text>
      </IconButton>);

    if (!tooltipText) {
      return (
        <div className={ styles.label }>
          { htmItem }
        </div>
      );
    }

    return (
      <div className={ styles.label }>
        <Tooltip
          className={ styles.tooltip_wrapper }
          renderContent={ () => renderTooltipContent(tooltipText) }
        >
          {htmItem}
        </Tooltip>
      </div>
    );
  };

  const { ProviderName = '', Fare, Routes } = parseJsonString(JsonData);

  const isItemCancelled = Status === VERSIONSTATUS.CANCELLED;

  const { BOOKED, COMPLETED } = TRIPSTATUS;

  const needApproval = BuyTripPersonal === APPROVE_SCHEME.ONLY_AFTER_APPROVE || BuyTripPersonal === APPROVE_SCHEME.ONLY_AFTER_APPROVE;

  const showAirEditCondition = () => isTicketChangeable(Fare?.IsTicketChangeable, Fare?.ChangeTicketWithCondition)
    && (isServiceTrip(status) && !isItemCancelled);

  const showHotelEditCondition =
    (isServiceTrip(status) && !isItemCancelled) ||
    (ServiceType === SERVICETYPE.HOTEL &&
      isActiveTrip(status) &&
      prepareRealCheckoutDate(CheckoutDate).isBefore(momentObject(new Date())));

  const isAir = ServiceType === SERVICETYPE.AIR;
  const isTrain = ServiceType === SERVICETYPE.TRAIN;
  const isAeroExpress = ServiceType === SERVICETYPE.AEROEXPRESS;
  const isTaxiVoucher = ServiceType === SERVICETYPE.TAXI_VOUCHER;
  const isTaxi = ServiceType === SERVICETYPE.TAXI;
  const isEvent = ServiceType === SERVICETYPE.EVENT;
  const isCustom = ServiceType === SERVICETYPE.CUSTOM;
  const isFlightCertificate = ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE;
  const isInsurance = ServiceType === SERVICETYPE.INSURANCE;
  const isStatusBooked = status === BOOKED;

  const cancelSelection = (isAir || isTrain || isAeroExpress) ? LABELS.AIR_CANCEL_ORDER : LABELS.CANCEL_ORDER;
  const calculationCancelSelection = (isAir || isTrain || isAeroExpress) ? LABELS.AIR_CALCULATION_CANCEL_ORDER : LABELS.CANCEL_ORDER;
  const editSelection = (isAir || isTrain || isAeroExpress) ? LABELS.AIR_EDIT_ORDER : LABELS.EDIT_ORDER;
  const editLabel = edit ? LABELS.REQUEST_WAS_SENT : editSelection;
  const refundLabel = refund ? LABELS.REQUEST_WAS_SENT : LABELS.REQUEST_REFUND;
  const cancelLabel = cancel ? LABELS.REQUEST_WAS_SENT : cancelSelection;
  const calculationCancelLabel = cancel ? LABELS.REQUEST_WAS_SENT : calculationCancelSelection;
  const isCancelledTrip = canCancelledTrip(status, Status);

  const hideCancelledTrip = ProviderName === AIRLINE_PROVIDERS.AVIACENTR;

  const checkRemoveFlightCertificate = accessFlightCertificates || !removeFlightCertificate;

  const showOrderFlightCertificate = (canBuyPersonalUnlimited || accessFlightCertificates) && (
    isAir &&
    !airTicketHasCertificate &&
    status !== TRIPSTATUS.CANCELLED &&
    checkRemoveFlightCertificate &&
    !isFreeFlightCertificate &&
    !isSmartAgent);

  const {
    tooltipTextEdit,
    tooltipTextCancel,
    onEditServiceFn,
    onCancelServiceFn,
    onCalculationCancelServiceFn,
  } = getSomeForHeader();

  const additionalHtml = (isAir && isStatusBooked) || showOrderFlightCertificate
    ? renderHeaderAdditionalServices()
    : null;
  const editHtml = isAir && !needApproval
    ? renderHeaderItemEditAir(editLabel, showAirEditCondition(), isCheckAfterDeparture(Routes, Fare?.IsTicketChangeableAfterDeparture))
    : (showHotelEditCondition && !isTaxiVoucher && !isTaxi && !isInsurance) &&
    renderHeaderEditLabels(editLabel, tooltipTextEdit, edit, onEditServiceFn);
  const refundsHtml = isInsurance && renderHeaderEditLabels(refundLabel, tooltipTextEdit, refund, onEditServiceFn);
  const cancelledHtml = (isCancelledTrip && !hideCancelledTrip && !isTaxiVoucher && !isTaxi && !isInsurance) &&
    renderHeaderCancelledLabels(cancelLabel, calculationCancelLabel, tooltipTextCancel, cancel, onCancelServiceFn, onCalculationCancelServiceFn, isCancelledTrip);

  const deleteHtml = isTaxiVoucher && renderDeleteButton(tooltipTextCancel, onCancelServiceFn);

  const renderToRequest = () => (
    <Link
      reactRouterLink
      className={ styles['request-link'] }
      to={ `${ROUTES.REQUESTS}/${RequestId}` }
      onClick={ send1CRequestClickAmp }
    >
      <IconButton iconType='menuRequests' iconColor='blue'>
        <Text type='NORMAL_14'>{LABELS.REQUEST}{RequestId}</Text>
      </IconButton>
    </Link>
  );

  const renderSelectionRequests = () => {
    const isRequestsSelectionCondition = getSalary1C
      && !isTaxi
      && !isCustom
      && !isTaxiVoucher
      && !isAeroExpress
      && !isFlightCertificate
      && (status === BOOKED || status === COMPLETED)
      && (isAdmin || canBuyAccount || canBuyPersonal)
      && !isEvent;

    if (!isRequestsSelectionCondition || isSmartAgent) return null;

    return (
      <Select
        search
        items={ tripsRequests }
        onChange={ (value) => onOpenApproveDialog(Id, value) }
        className={ styles['request-link'] }
        placeholder={ LABELS.REQUEST_NUMBER }
        searchPlaceholder={ LABELS.ENTER_REQUEST_NUMBER }
        theme='default-small'
      />
    );
  };

  const checkRequests = RequestId ? renderToRequest() : renderSelectionRequests();

  return (
    <div className={ styles.wrapper }>
      { renderVoidInfo() }
      <div className={ styles.actions }>
        { checkRequests }
        { additionalHtml }
        { editHtml }
        { refundsHtml }
        { cancelledHtml }
        { deleteHtml }
      </div>
    </div>
  );
};

export { OrderHeader };
