import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { Tooltip, Text, IconButton, Button } from 'new-ui';

import { getText } from '../../../i18n';

import MainHeaderItems from './components/MainHeaderItems';
import Balance from './components/Balance';
import ItemWrap from './components/ItemWrap';
import CalendarSa from '../CalendarSa';
import { MainHeaderLogo } from '../MainHeaderLogo';

import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { IHeaderProps } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  LOG_OUT: getText('components:header.logout'),
  SETTINGS: getText('components:header.settings'),
  CALENDAR: getText('components:header.calendar'),
};

const MainHeader = ({
  smartdeskService,
  featureFlagsService,
  featureFlagsService: {
    getShowElementForSmartagent,
  },
  appService,
  reportService,
  bonusProgramService,
  onOpenTravelChat,
  handleLogout,
  onClick,
  isDemo,
  account: {
    rights,
  },
  header: {
    balances,
    noteItemsCount,
    favoriteItemsCount,
  },
  logoData: {
    img,
    name,
    iconSmartway,
  },
  disableS7Balance,
  sendIntercomAfterLoadPage,
  company,
  paymentService,
  showPaymentButton,
  airlineService,
  history,
  onChangeAgentMode,
}: IHeaderProps) => {
  const [showSmartdeskPanel, setShowSmartdeskPanel] = useState(false);

  const labelSettings = (
    <MediaQuery minWidth={ 600 }>
      { LABELS.SETTINGS }
    </MediaQuery>
  );

  const renderMenu = () => {
    if (isSmartAgent) {
      return null;
    }

    const tooltipContent = () => (
      <div className={ styles.content }>
        <Text
          type='NORMAL_14_130'
          className={ `${styles.label} ${styles.text}` }
        >
          { LABELS.LOG_OUT }
        </Text>
        <Text className={ styles.text } type='SEMIBOLD_14'>
          { rights.Login }
        </Text>
      </div>
    );

    return (
      <div className={ styles.settings }>
        <div className={ styles.with_border }>
          <ItemWrap
            subItem
            href={ ROUTES.SETTINGS.MAIN }
            getIcon={ () => 'menuSettings' }
            label={ labelSettings }
            onClick={ () => onClick(LABELS.SETTINGS, 0) }
            getShowElementForSmartagent={ getShowElementForSmartagent() }
            qaAttr={ QA_ATTRIBUTES.header.settings }
          />
        </div>
        <div className={ styles.with_border }>
          <Tooltip
            className={ styles.logout_wrapper }
            renderContent={ () => tooltipContent() }
            position='bottom'
          >
            <IconButton
              qaAttr={ QA_ATTRIBUTES.header.logout }
              className={ styles.text }
              onClick={ handleLogout }
              iconType='menuLogout'
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderCalendarEvents = () => (
    <div className={ styles.tooltip_container }>
      <CalendarSa
        isShow={ getShowElementForSmartagent() && isSmartAgent }
        smartdeskService={ smartdeskService }
        handleShowSmartdeskPanel={ () => setShowSmartdeskPanel(false) }
      />
    </div>
  );

  const renderTooltipEvents = () => {
    if (showSmartdeskPanel || !getShowElementForSmartagent() || !isSmartAgent) {
      return null;
    }

    return (
      <div
        className={ styles.tooltip_wrapper }
        onChange={ () => setShowSmartdeskPanel(prevState => !prevState) }
      >
        <Tooltip
          className={ styles.tooltip }
          bodyClassName={ styles.tooltip_body }
          renderContent={ renderCalendarEvents }
          clickable
          position='bottom-with-right'
          theme='white-without-foot'
        >
          <Button
            type='textual-black'
            className={ styles.calendar_button }
            onClick={ () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINBALANCE_ADD_PRESSED) }
          >
            { LABELS.CALENDAR }
          </Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      <div className={ styles.header }>
        <div className={ styles.wrap }>
          <div className={ styles.container }>
            <MainHeaderLogo
              styles={ styles }
              img={ img }
              name={ name }
              iconSmartway={ iconSmartway }
              airlineService={ airlineService }
            />
            <Balance
              balances={ balances }
              accountRights={ rights }
              reportService={ reportService }
              bonusProgramService={ bonusProgramService }
              disableS7Balance={ disableS7Balance }
              showPaymentButton={ showPaymentButton }
              company={ company }
              paymentService={ paymentService }
              featureFlagsService={ featureFlagsService }
              history={ history }
            />
          </div>
          <div className={ styles.menu }>
            { renderTooltipEvents() }
            <div className={ styles.links }>
              <MainHeaderItems
                // accountRights={ rights }
                getShowElementForSmartagent={ getShowElementForSmartagent() && isSmartAgent }
                balances={ balances }
                noteItemsCount={ noteItemsCount }
                favoriteItemsCount={ favoriteItemsCount }
                onOpenTravelChat={ onOpenTravelChat }
                onClick={ onClick }
                isDemo={ isDemo }
                handleLogout={ handleLogout }
                qaAttrFavorites={ QA_ATTRIBUTES.header.favorites }
                qaAttrFavoritesIcon={ QA_ATTRIBUTES.header.favoriteIcons }
                sendIntercomAfterLoadPage={ sendIntercomAfterLoadPage }
                appService={ appService }
                onChangeAgentMode={ onChangeAgentMode }
              />
              { renderMenu() }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
