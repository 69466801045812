import React from 'react';
import { Button, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { isWithoutMentionSmartway } from '../../bi/utils/companies';
import { isSmartAgent } from '../../bi/utils/env';

import { SPACE_OR_HYPHEN } from '../../bi/constants/regExp';
import ROUTES from '../../bi/constants/routes';

import styles from './styles/index.module.css';
import { MainAnalytic } from '../../bi/utils/analytics';

const LABELS = {
  TRAVEL_HELPER: getText('components:footer.travelHelper'),
  HELP: getText('components:footer.help'),
  TELEGRAM: getText('components:footer.telegram'),
  QUALITY_CONTROL: getText('components:footer.quality'),
  requisites: {
    address: getText('components:footer.requisites.address'),
    name: getText('components:footer.requisites.name'),
    otherInn: getText('components:footer.requisites.otherInn'),
    otherOgrn: getText('components:footer.requisites.otherOgrn'),
  },
};

interface FooterProps {
  copyrightName: string,
  email: string,
  phone: string,
  wiki: string,
  companyName: string,
  handleOpenTravelChat(): void,
  handleOpenQuality?(): void,
  getShowElementForSmartagent?: boolean,
}

const Footer = React.memo(({
  copyrightName,
  email,
  phone,
  wiki,
  companyName,
  handleOpenTravelChat,
  handleOpenQuality = () => {},
  getShowElementForSmartagent = false,
}: FooterProps) => {
  const { address, name, otherInn, otherOgrn } = LABELS.requisites;
  const phoneReplace = phone.replace(SPACE_OR_HYPHEN, '');

  const renderHelp = () => {
    if (isSmartAgent || isWithoutMentionSmartway(companyName)) {
      return null;
    }

    return (
      <Text type='NORMAL_14'>
        <a href={ wiki } target='_blank' rel='noreferrer'> { LABELS.HELP } </a>
      </Text>
    );
  };

  const renderTelegramLink = () => {
    if (isSmartAgent || isWithoutMentionSmartway(companyName)) {
      return null;
    }

    return (
      <div className={ styles.item }>
        <div className={ styles.telegram }>
          <Text className={ styles.helper } type='NORMAL_14'>
            <a
              href={ ROUTES.SMARTWAY_TELEGRAM }
              target='_blank'
              rel='noreferrer'
            >
              { LABELS.TELEGRAM }
            </a>
          </Text>
        </div>
      </div>
    );
  };

  const renderQualityControl = () => {
    if (!isSmartAgent || !getShowElementForSmartagent) {
      return null;
    }

    return (
      <Button
        type='textual'
        onClick={ () => handleOpenQuality() }
        className={ styles.quality }
      >
        <Text
          className={ styles.helper }
          type='NORMAL_14'
        >
          { LABELS.QUALITY_CONTROL }
        </Text>
      </Button>
    );
  };

  const renderText = (text: string) => <Text type='NORMAL_14'>{ text }</Text>;

  const handleOpenChat = () => {
    handleOpenTravelChat();

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAIN_SMARTHELP_OPENED('footer'));
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.left }>
        <div className={ styles.item }>
          <Text type='NORMAL_14'>
            &copy; { copyrightName }
          </Text>
        </div>
        <div className={ styles.item }>
          <Text type='NORMAL_14'>
            <a href={ `tel:${phoneReplace}` }>{ phone }</a>
          </Text>
        </div>
        <div className={ styles.item }>
          <Text type='NORMAL_14'>
            <a href={ `mailto:${email}` }>{ email }</a>
          </Text>
        </div>
        <div className={ styles.item }>
          { renderHelp() }
        </div>
        <div className={ styles.req }>
          {renderText(address)}
          {renderText(name)}
          {renderText(otherInn)}
          {renderText(otherOgrn)}
        </div>
      </div>
      <div className={ styles.wrapper_right_column }>
        { renderQualityControl() }
        <div className={ styles.row }>
          <Button
            type='textual'
            className={ styles.location }
            onClick={ handleOpenChat }
          >
            <Text type='NORMAL_14' className={ styles.helper }>
              { LABELS.TRAVEL_HELPER }
            </Text>
          </Button>
          { renderTelegramLink() }
        </div>
      </div>
    </div>
  );
});

export default Footer;
