import React from 'react';
import moment, { Moment } from 'moment';

import { Dialog, Button, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { getEmployeeTrainList } from '../../bi/utils/train';

import { PATTERN } from '../../bi/constants/dateFormats';

import { ISourcesEmployees } from '../../bi/services/checkout/types';

import styles from './index.module.css';

const LABELS = {
  TRIP_INFO: (
    route: string,
    id: string,
    name: string,
    date: string,
  ) => getText('components:cartFromIm.tripInfo', { route, id, name, date }),
  REMOVE_INFO: getText('components:cartFromIm.removeInfo'),
  ON_REMOVE: getText('note:dialog.onRemove'),
  ON_CLOSE: getText('note:dialog.onClose'),
  DIALOG_TITLE: getText('components:cartFromIm.dialogTitle'),
};

interface IItem {
  Id: number;
  reservId: string,
  from: string,
  to: string,
  allowedEmployees: ISourcesEmployees[],
  departureDate: Moment,
  arrivalDate: Moment,
  isReserved?: false
}

interface IRemoveTrainItemDialog {
  items: IItem[],
  showRemoveItemDialog: boolean,
  onClose: () => void,
  onDelete: () => void,
}

const RemoveTrainItemDialog: React.FC<IRemoveTrainItemDialog> = ({
  items,
  showRemoveItemDialog,
  onClose,
  onDelete,
}) => {
  const formatTripDates = (
    depDate: Moment,
    arrivDate: Moment,
  ) => {
    const departureFormatted = moment(depDate).format(PATTERN.DAY_OF_MONTH_SHORT);
    const arrivalFormatted = moment(arrivDate).format(PATTERN.DAY_OF_MONTH_SHORT);

    if (departureFormatted === arrivalFormatted) {
      return departureFormatted;
    }

    return `${departureFormatted} - ${arrivalFormatted}`;
  };

  const renderItem = (item: IItem) => {
    const {
      reservId,
      from,
      to,
      allowedEmployees,
      departureDate,
      arrivalDate,
    } = item;

    const tripRoute = `${from.toUpperCase()} - ${to.toUpperCase()}`;
    const dateTrip = formatTripDates(departureDate, arrivalDate);

    return (
      <div key={ item.Id } className={ styles['item-info'] }>
        <Text className={ styles.text }>
          { LABELS.TRIP_INFO(
            tripRoute,
            reservId,
            getEmployeeTrainList(allowedEmployees),
            dateTrip,
          )}
        </Text>
      </div>
    );
  };

  return (
    <Dialog
      show={ showRemoveItemDialog }
      onChange={ onClose }
      showClosing
    >
      <div className={ styles['dialog-content'] }>
        <Text
          type='bold_20_130'
          className={ styles.title }
        >
          { LABELS.DIALOG_TITLE }
        </Text>
        <div className={ styles['item-info'] }>
          {items.map(item => renderItem(item))}
        </div>
        <Text className={ styles.text }>{ LABELS.REMOVE_INFO }</Text>

        <div className={ styles.actions }>
          <Button
            type='primary'
            onClick={ onDelete }
          >
            { LABELS.ON_REMOVE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onClose }
          >
            { LABELS.ON_CLOSE }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { RemoveTrainItemDialog };
