import React, { FC } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { PROVIDER_IM } from '../../bi/constants/train';

const LABELS = {
  IS_PROVIDER_IM: getText('trains:ticket.isProviderIM'),
};

interface InfoProviderIMProps {
  providerName: string;
  className?: string;
}

const InfoProviderIM: FC<InfoProviderIMProps> = ({ providerName, className }) => {
  if (providerName !== PROVIDER_IM) {
    return null;
  }

  return (
    <Text className={ className } type='NORMAL_14' color='gray'>
      {LABELS.IS_PROVIDER_IM}
    </Text>
  );
};

export { InfoProviderIM };
