import React, { Component, createRef } from 'react';
import { Button, Dialog, MultiSelect, Tooltip, Text, Input, Icon } from 'new-ui';

import { observer } from 'mobx-react';
import { getText, getTextArray } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import { ITripTagsService } from '../../bi/types/tripTags';
import { WorkspaceService } from '../../bi/types/workspace';

import TYPES from '../../bi/constants/tripTags';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LINKNAME = getText('components:tripTags.linkName');
const DEFAULTTOOLTIP = getTextArray('components:tripTags.defaultTooltip');
const DEFAULT_TOOLTIP_SMARTAGENT = getTextArray('components:tripTags.defaultTooltipSmartagent');
const EMPLOYEEPAGETOOLTIP = getTextArray('components:tripTags.employeePageTooltip');
const EMPLOYEE_PAGE_TOOLTIP_SMARTAGENT = getTextArray('components:tripTags.employeePageTooltipSmartagent');
const DEFAULTDESCRIPTION = getText('components:tripTags.tags');
const EMPLOYEEDESCRIPTION = getText('components:tripTags.defaultTags');
const MULTISELECT = {
  LABEL: getText('components:tripTags.select'),
  SERCHPLACEHOLDER: getText('components:tripTags.findTag'),
  ADDBUTTONLABEL: getText('components:tripTags.addNewTag'),
};
const DIALOGNEWTAG = {
  PLACEHOLDER: getText('components:tripTags.newTagDialog.placeholder'),
  SAVE: getText('common:create'),
  CANCEL: getText('common:undo'),
  WIDTH: '400px',
  NEW_TAG_DIALOD_TITLE: getText('components:tripTags.newTagDialog.title'),
};

const defaultTooltip = isSmartAgent ? DEFAULT_TOOLTIP_SMARTAGENT : DEFAULTTOOLTIP;
const employeePageTooltip = isSmartAgent ? EMPLOYEE_PAGE_TOOLTIP_SMARTAGENT : EMPLOYEEPAGETOOLTIP;

const TOOLTIPS = {
  [TYPES.DEFAULT]: defaultTooltip,
  [TYPES.EMPLOYEEPAGE]: employeePageTooltip,
};
const DESCRIPTIONS = {
  [TYPES.DEFAULT]: DEFAULTDESCRIPTION,
  [TYPES.EMPLOYEEPAGE]: EMPLOYEEDESCRIPTION,
};

const { employee: { documents: { additionally: { newLinkModal } } } } = QA_ATTRIBUTES;

interface ITripTagsProps {
  tripTagsService: ITripTagsService,
  workspaceService: WorkspaceService,
  store: any,
  onChangeTags: (value: number[]) => void,
  onUpdateTags?: (value: number[]) => void,
  selectedTags: any,
  noeditable?: boolean,
  className?: string,
  descriptionClassName?: string,
  type?: 'DEFAULT' | 'EMPLOYEEPAGE',
  qaAttr?: string,
  qaAttrLink?: string,
  qaAttrSearch?: string,
  qaAttrAdd?: string,
}

interface ITripTagsState {
  isShowSelect: boolean,
  isShowModalAddTag: boolean,
  newTripTagInput: string,
}

@observer
class TripTags extends Component<ITripTagsProps, ITripTagsState> {
  state = {
    isShowSelect: false,
    isShowModalAddTag: false,
    newTripTagInput: '',
  };

  static defaultProps = {
    onUpdateTags: () => {},
    type: 'DEFAULT',
  };

  inputRef: React.RefObject<HTMLInputElement> = createRef();

  componentWillUnmount() {
    const { onUpdateTags, selectedTags } = this.props;
    const { isShowSelect } = this.state;

    if (isShowSelect && onUpdateTags) {
      onUpdateTags(selectedTags);
    }
  }

  setShowModalAddTag = (isShowModalAddTag: boolean) => {
    this.setState({ isShowModalAddTag }, () => {
      if (isShowModalAddTag) {
        this.toggleShowSelect(false);
      }
    });
  };

  toggleShowSelect = (isShowSelect: boolean) => {
    const { onUpdateTags, type, selectedTags } = this.props;

    return this.setState({ isShowSelect }, () => {
      if (!isShowSelect && type !== TYPES.EMPLOYEEPAGE && onUpdateTags) {
        onUpdateTags(selectedTags);
      }
    });
  };

  handleChangeInput = (newTripTagInput: string) => this.setState({ newTripTagInput });

  handleAddNewTag = () => {
    const { tripTagsService: { addAccountTripTag, addTripTag }, onChangeTags, onUpdateTags, selectedTags, type } = this.props;
    const { newTripTagInput } = this.state;

    this.setShowModalAddTag(false);

    return addAccountTripTag(newTripTagInput)
      .then(({ Id, Value }: { Id: number, Value: string }) => this.setState({
        newTripTagInput: '',
      }, () => {
        const updatedSelectedTags = [...selectedTags, Id];

        addTripTag({ Id, Value });
        onChangeTags(updatedSelectedTags);

        return type !== TYPES.EMPLOYEEPAGE && onUpdateTags ? onUpdateTags(updatedSelectedTags) : null;
      }));
  };

  mapText = (stringsArray: any) => stringsArray.map((text: string, index: number) => (<Text type='NORMAL_14_130' color='white' key={ index }>{ text }</Text>));

  getSelectedString = () => {
    const { selectedTags, store: { mappedTripTags } } = this.props;

    return selectedTags.reduce((arr: any, id: number) => {
      const tagItem = mappedTripTags.find(({ value }: { value: number }) => value === id);

      return tagItem && tagItem.label ? [...arr, tagItem.label] : arr;
    }, []).join(', ');
  };

  renderShowModalAddTag = () => {
    const { isShowModalAddTag, newTripTagInput } = this.state;

    return (
      <Dialog
        showClosing
        show={ isShowModalAddTag }
        onChange={ this.setShowModalAddTag }
      >
        <div className={ styles.dialog }>
          <Text qaAttr={ newLinkModal.title } type='bold_20' className={ styles.header }>{ DIALOGNEWTAG.NEW_TAG_DIALOD_TITLE }</Text>
          <Input
            value={ newTripTagInput }
            placeholder={ DIALOGNEWTAG.PLACEHOLDER }
            onChange={ this.handleChangeInput }
            ref={ this.inputRef }
            qaAttr={ newLinkModal.search }
          />
          <div className={ styles.actions }>
            <Button
              disabled={ !newTripTagInput }
              type='primary'
              onClick={ () => this.handleAddNewTag() }
              qaAttr={ newLinkModal.create }
            >
              { DIALOGNEWTAG.SAVE }
            </Button>
            <Button
              onClick={ () => this.setShowModalAddTag(false) }
              type='textual-medium'
              className={ styles.close }
              qaAttr={ newLinkModal.cancel }
            >
              { DIALOGNEWTAG.CANCEL }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderTooltipText = () => {
    const { type } = this.props;

    return type ? this.mapText(TOOLTIPS[type]) : null;
  };

  renderQuestionTooltip = () => {
    const { selectedTags } = this.props;

    if (selectedTags.length > 0) return null;

    return (
      <>
        &nbsp;
        <Tooltip
          className={ styles.info }
          renderContent={ () => (
            <div className={ styles['tooltip-wrapper'] }>
              { this.renderTooltipText() }
            </div>
          ) }
        >
          <Icon type='question' />
        </Tooltip>
      </>
    );
  };

  renderDescriptionText = () => {
    const { type } = this.props;

    return type ? (
      <Text color='gray' className={ styles.label }>{ DESCRIPTIONS[type] }</Text>) : null;
  };

  renderDescription = () => {
    const { noeditable, selectedTags, descriptionClassName, qaAttrLink, qaAttr } = this.props;

    const hasSelectedTags = selectedTags.length > 0;

    const linkName = hasSelectedTags ? this.getSelectedString() : LINKNAME;

    const buttonClassNames = [];

    if (hasSelectedTags) {
      buttonClassNames.push(styles.selected_tags);
    }

    const descriptionClassNames = [styles.description];

    if (descriptionClassName) {
      descriptionClassNames.push(descriptionClassName);
    }

    const classNames = descriptionClassNames.join(' ');

    if (noeditable) {
      return (
        <div className={ classNames }>
          { this.renderDescriptionText() }
          <Text className={ styles['no-editable'] }>{ linkName }</Text>
        </div>
      );
    }

    return (
      <div data-qa={ qaAttr } className={ classNames }>
        { this.renderDescriptionText() }
        &nbsp;
        <Button
          tabIndex={ -1 }
          className={ buttonClassNames.join(' ') }
          type='textual'
          onClick={ () => this.toggleShowSelect(true) }
          qaAttr={ qaAttrLink }
        >
          { linkName }
        </Button>
        { this.renderQuestionTooltip() }
      </div>
    );
  };

  renderSelect = () => {
    const { onChangeTags, selectedTags, store: { mappedTripTags }, workspaceService, qaAttrSearch, qaAttrAdd } = this.props;

    const showAddButton = !workspaceService.isDemo;

    return (
      <div className={ styles.select }>
        <MultiSelect
          opened
          search
          theme='border'
          itemClassName={ styles.item }
          addButton={ showAddButton }
          withArrow={ false }
          list={ mappedTripTags }
          values={ selectedTags }
          onChange={ onChangeTags as () => void }
          onAdd={ () => this.setShowModalAddTag(true) }
          onClose={ () => this.toggleShowSelect(false) }
          placeholder={ MULTISELECT.LABEL }
          searchPlaceholder={ MULTISELECT.SERCHPLACEHOLDER }
          addButtonLabel={ MULTISELECT.ADDBUTTONLABEL }
          qaAttrSearch={ qaAttrSearch }
          qaAttrAdd={ qaAttrAdd }
        />
      </div>
    );
  };

  render() {
    const { className, noeditable, selectedTags } = this.props;
    const { isShowSelect } = this.state;

    if (noeditable && selectedTags.length === 0) return null;

    const classNames = `${styles.wrapper} ${className}`.trim();
    const tagsHtml = isShowSelect && !noeditable ? this.renderSelect() : this.renderDescription();

    return (
      <div className={ classNames }>
        { tagsHtml }
        { this.renderShowModalAddTag() }
      </div>
    );
  }
}

export { TripTags };
