interface IBookingService {
  getState: () => IBookingStore,
}

interface IBookingStore {
  statuses: IStatus[],
}

interface IStatus {
  AdditionalPrice: number,
  AnalogRooms: any[],
  BookingStep: string,
  CartItemId: number,
  Changes: string,
  Error: any | null,
  NewPrice: number,
  PriceOrigin: number,
}

enum CHANGES {
  NoChanges = 'NoChanges',
  PriceChanges = 'PriceChanges',
  NotEnoughPlaces = 'NotEnoughPlaces',
  SoldOut = 'SoldOut',
}

enum BOOKINGSTEP {
  Unknown = 'Unknown',
  Metadata = 'Metadata',
  PrepareToReserve = 'PrepareToReserve',
  Reservation = 'Reservation',
  Reserved = 'Reserved',
  ReservationError = 'ReservationError',
  PrepareToBooking = 'PrepareToBooking',
  Booking = 'Booking',
  Booked = 'Booked',
  BookingError = 'BookingError',
  ReadyToBook = 'ReadyToBook',
}

export { BOOKINGSTEP, CHANGES };

export type { IBookingService };
