import React, { FC } from 'react';
import { Text } from 'new-ui';

interface TrainInterface {
  StationFrom: string;
  StationTo: string;
  Route: string[];
}

interface InfoPathTrainProps {
  train: TrainInterface;
  className: string;
}

const DASH = '—';

const InfoPathTrain: FC<InfoPathTrainProps> = ({ train, className }) => {
  const { StationFrom, StationTo, Route } = train;

  const renderEndingStations = (isStartStation: boolean) => {
    const userStation = isStartStation ? StationFrom : StationTo;

    if (!Route?.length || !Route[0] || !Route[1]) return null;

    const endingStation = isStartStation ? Route[0] : Route[1];

    if (userStation.toLowerCase() !== endingStation.toLowerCase()) {
      return isStartStation ? (
        <Text type='NORMAL_14'>
          {endingStation.toLowerCase()} {DASH}&nbsp;
        </Text>
      ) : (
        <Text type='NORMAL_14'>
          &nbsp;{DASH} {endingStation.toLowerCase()}
        </Text>
      );
    }

    return null;
  };

  const [from, to] = [StationFrom, StationTo].map(station =>
    station.toLowerCase(),
  );

  return (
    <Text className={ className } type='bold_14' color='gray'>
      {renderEndingStations(true)} {from} {DASH} {to} {renderEndingStations(false)}
    </Text>
  );
};

export { InfoPathTrain };
