import React, { createRef, JSX } from 'react';
import { Suggest } from 'new-ui';

import { getText } from '../../../i18n';

import AccountSettings from '../../bi/services/accountSettings';

import { EmployeeCloneItem } from '../EmployeeCloneItem';

import { isSmartAgent } from '../../bi/utils/env';

import { EmployeeType } from '../../bi/services/employee/consts';
import { EmployeesObj } from '../../bi/types/employees';

import styles from './styles/index.module.css';

const LABELS = {
  TRAVELER: getText('components:employeeSuggest.traveler'),
  EMPLOYEE: getText('components:employeeSuggest.employee'),
  TRAVELR_UPPER_CASE: getText('components:employeeSuggest.travelerUpperCase'),
  EMPLOYEE_UPPER_CASE: getText('components:employeeSuggest.employeeUpperCase'),
  EMPLOYEE_NAME: (user: string) => getText('components:employeeSuggest.employeeName', { user }),
  ADD_EMPLOYEE: (user: string) => getText('components:employeeSuggest.addEmployee', { user }),
};

interface IEmployeeSuggestProps<E extends object = Partial<EmployeeType>> {
  onSelect: (obj: E) => void;
  onFetch: (value: string) => Promise<E[]>;
  withFetching?: boolean,
  value?: E | null,
  createNewEmployee?: () => void | Promise<void>,
  canAddEmployee?: (() => void | Promise<void>) | boolean,
  autoFocus?: boolean,
  qaAttrAddEmployee?: string,
  qaAttrFirstEl?: string,
  qaAttrInputEmployee?: string,
  qaAttrAddEmployeeLoading?: string,
  qaAttrSelectEmployeeLoading?: string,
  accountSettingsService?: AccountSettings,
  debounceMs?: number,
  userLoading?: boolean,
}

interface IEmployeeSuggestState {
  list: EmployeeType[];
  value: number | string;
  isFetching: boolean;
}

class EmployeeSuggest extends React.Component<IEmployeeSuggestProps, IEmployeeSuggestState> {
  static defaultProps = {
    withFetching: false,
    value: {},
    autoFocus: false,
    canAddEmployee: false,
    qaAttrAddEmployee: '',
    qaAttrFirstEl: '',
    qaAttrInputEmployee: '',
    qaAttrAddEmployeeLoading: '',
    qaAttrSelectEmployeeLoading: '',
    debounceMs: 300,
    accountSettingsService: null,
    userLoading: false,
    createNewEmployee: () => {},
  };

  travelerOrEmployeeToName: string;
  travelerOrEmployeeToAddName: string;
  ref: React.Ref<HTMLInputElement>;

  componentDidUpdate = (prevProps: IEmployeeSuggestProps) => {
    const { value } = this.props;

    if (prevProps.value && value && prevProps.value.Id !== value.Id) {
      this.setState({ ...this.state, value: value.Id ? this.getSuggestionValue(value as EmployeeType) : '' });
    }
  };

  constructor(props: IEmployeeSuggestProps) {
    super(props);

    const { value } = this.props;

    const val = value?.Id ? this.getSuggestionValue(value as EmployeeType) : '';

    this.travelerOrEmployeeToName = isSmartAgent
      ? LABELS.TRAVELR_UPPER_CASE
      : LABELS.EMPLOYEE_UPPER_CASE;
    this.travelerOrEmployeeToAddName = isSmartAgent
      ? LABELS.TRAVELER
      : LABELS.EMPLOYEE;

    this.state = {
      list: [],
      value: val,
      isFetching: true,
    };
    this.ref = createRef();
  }

  getSuggestionValue = ({ Surname, Name, Patronymic }: EmployeeType) => {
    const patronymic = Patronymic ? ` ${Patronymic}` : '';

    return `${Surname} ${Name}${patronymic}`;
  };

  onInputChange = (value: string) => {
    this.setState({ value });
    this.onFetchRequest(value);
  };

  onFetchRequest = (value: string) => {
    const { onFetch } = this.props;

    this.setFetching(true);

    onFetch(value).then(list => {
      if (list !== null) {
        this.setState({ list } as { list: EmployeeType[] });
        this.setFetching(false);
      }
    });
  };

  setFetching = (value: boolean) => this.setState({ isFetching: value });

  handleClearRequest = () => this.setState({ list: [] });

  handleSelectClick = () => {
    const { createNewEmployee } = this.props;

    this.setState({ value: this.getSuggestionValue({} as EmployeeType) });

    this.handleClearRequest();

    return createNewEmployee && createNewEmployee();
  };

  onSelect = (suggestion: object) => {
    if (!suggestion) {
      return null;
    }

    const { onSelect } = this.props;

    this.setState({ value: this.getSuggestionValue(suggestion as EmployeeType) });

    this.handleClearRequest();

    return onSelect(suggestion);
  };

  renderSuggestion = (suggestion: EmployeesObj & { addNewEmployee: boolean; }) => {
    const { accountSettingsService } = this.props;

    const displayFF = accountSettingsService
      ? accountSettingsService.getHowEmployeeDisplay()
      : [];
    const displayBirthday = accountSettingsService
      ? accountSettingsService.getCartEmployeeBirthday()
      : false;

    return (
      <EmployeeCloneItem
        value={ suggestion }
        highlitedWord={ this.state.value as string }
        className={ styles.suggestion }
        qaAttr={ this.props.qaAttrAddEmployee }
        displayBirthday={ displayBirthday }
        displayFF={ displayFF as [] }
      />
    );
  };

  render() {
    const {
      canAddEmployee,
      autoFocus,
      withFetching,
      qaAttrFirstEl,
      qaAttrInputEmployee,
      qaAttrAddEmployeeLoading,
      qaAttrSelectEmployeeLoading,
      debounceMs,
      userLoading,
    } = this.props;
    const { list, value, isFetching } = this.state;

    const buttonTheme = isSmartAgent ? 'green' : 'default';

    const preparedList = withFetching && isFetching ? [] : list;

    return (
      <div className={ styles.wrapper }>
        <Suggest
          addButton={ canAddEmployee as boolean }
          addButtonLabel={ LABELS.ADD_EMPLOYEE(this.travelerOrEmployeeToAddName) }
          onAdd={ this.handleSelectClick }
          placeholder={ LABELS.EMPLOYEE_NAME(this.travelerOrEmployeeToName) }
          theme='border'
          value={ value as string }
          items={ preparedList as [] }
          onChange={ this.onInputChange }
          renderItem={ this.renderSuggestion as () => JSX.Element }
          onSelect={ this.onSelect }
          ref={ this.ref }
          loading={ userLoading }
          autoFocus={ autoFocus }
          withLabel={ false }
          keyExtractor={ (({ Id }: EmployeeType) => Id || Math.random() * Math.random()) as () => number }
          shouldFocusItem={ () => (value ? (value as unknown as []).length > 0 : false) }
          qaAttrFirstEl={ qaAttrFirstEl }
          qaAttr={ qaAttrInputEmployee }
          qaAttrLoading={ qaAttrAddEmployeeLoading }
          qaAttrLoadingSuggest={ qaAttrSelectEmployeeLoading }
          debounceMs={ debounceMs }
          isFetching={ isFetching }
          buttonTheme={ buttonTheme }
        />
      </div>
    );
  }
}

export { EmployeeSuggest };
