// @ts-nocheck
import React from 'react';
import { Button, Dialog, DotLoading, Text, PROPS, Price } from 'new-ui';
import { getText } from '../../../i18n';

import { getEmployeeFullNameWithSimplicity } from '../../bi/utils/employees';
import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';

import styles from './styles/index.module.css';

interface EmployeeType {
  Id: number,
  Surname: string,
  Name: string,
  Patronymic: string,
}

interface DiscountType {
  Amount: number,
  Apply: boolean,
  BackwardId: number,
}

interface ItemType {
  Id: number,
  FirstDate: string,
  SecondDate: string,
  Discount: DiscountType,
  Employees: EmployeeType[],
}

interface DiscountDialogProps {
  items: ItemType[],
  show: boolean,
  isProviderIM: boolean,
  againCanReserve: boolean,
  onApply: () => void,
  onClose: () => void,
}

interface DiscountDialogState {
  loadingApply: boolean,
  loading: boolean,
}

const LABELS = {
  APPLY: getText('components:discountDialog.apply'),
  UNAPPLY: getText('components:discountDialog.unApply'),
  DISCOUNT: getText('components:discountDialog.discount'),
  INFO: {
    TITLE: getText('components:discountDialog.info.title'),
    DESCRIPTION: {
      FIRST: getText('components:discountDialog.info.description.first'),
      SECOND: getText('components:discountDialog.info.description.second'),
    },
  },
};

class DiscountDialog extends React.Component<DiscountDialogProps, DiscountDialogState> {
  state: DiscountDialogState = {
    loadingApply: false,
    loading: false,
  };

  toggleLoadingApply = (): void => this.setState({
    loadingApply: !this.state.loadingApply,
  });

  toggleLoading = (): void => this.setState({
    loading: !this.state.loading,
  });

  handleLoading = (value: any): void => {
    if (value) {
      return this.toggleLoadingApply();
    }

    return this.toggleLoading();
  };

  handleApply = (value: any): void => {
    this.handleLoading(value);
    this.props.onApply(value);
  };

  renderItem = ({ Id, FirstDate, SecondDate, Employees, Discount: { Amount } }, index) => {
    const dates = formatRangeDateWithSimplicity(FirstDate, SecondDate);
    const employee = getEmployeeFullNameWithSimplicity(Employees[0], true);

    return (
      <Text key={ `discount_${index}_${Id}` } className={ styles.item }>

        {employee}, {dates}: {LABELS.DISCOUNT}&nbsp;<Price value={ Amount } marginSmall type={ PROPS.TEXT.TYPES.BOLD_20 } />
      </Text>
    );
  };

  renderLoading = () => (
    <div className={ styles.loader }>
      <DotLoading />
    </div>
  );

  renderInfo = () => {
    const { loadingApply } = this.state;
    const { items } = this.props;

    return (
      <div className={ styles.wrapper }>
        <Text type={ PROPS.TEXT.TYPES.BOLD_20 } className={ styles.header }>
          {LABELS.INFO.TITLE}
        </Text>
        <Text type={ PROPS.TEXT.TYPES.NORMAL_16_140 }>
          {LABELS.INFO.DESCRIPTION.FIRST}
        </Text>
        <Text className={ styles.title }>
          {LABELS.INFO.DESCRIPTION.SECOND}
        </Text>
        <div className={ styles.list }>
          {items.map(this.renderItem)}
        </div>
        <div className={ styles.action }>
          <Button
            loading={ loadingApply }
            type={ PROPS.BUTTON.TYPES.SECONDARY }
            onClick={ (event) => {
              this.handleApply(true);
              event.stopPropagation();
            } }
          >
            { LABELS.APPLY }
          </Button>
          <Button
            onClick={ (event) => {
              this.handleApply(false);
              event.stopPropagation();
            } }
            type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
            className={ styles.cancel }
          >
            { LABELS.UNAPPLY }
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { show, onClose, isProviderIM, againCanReserve } = this.props;
    const typeClose = isProviderIM && againCanReserve ? () => this.handleApply(false) : onClose;
    const html = this.state.loading ? this.renderLoading() : this.renderInfo();

    return (
      <Dialog show={ show } showClosing onChange={ typeClose }>
        { html }
      </Dialog>
    );
  }
}

export default DiscountDialog;
