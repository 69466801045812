import React, { Component } from 'react';
import { Button, Text, ItemPanel, DotLoading } from 'new-ui';
import { getText } from '../../../i18n';

import guid from '../../bi/utils/guid';
import parseUnix from '../../bi/utils/parseDateTime';
import { canCancelledTrip } from '../../bi/utils/tripStatus';
import parseJsonString from '../../bi/utils/parseJsonString';
import {
  CompanySetting,
  getFreeFlightCertificate,
  getTransformationCompanySettings,
  SettingsType,
} from '../../bi/utils/accountSettings';
import { MainAnalytic } from '../../bi/utils/analytics';

import { ADDITIONAL_SERVICES, AIRLINE_PROVIDERS } from '../../bi/constants/airline';
import { ANALYTIC_SERVICE_TYPE_MATCH, SERVICETYPE } from '../../bi/constants/serviceType';
import { TRIPSTATUS } from '../../bi/constants/trips';

// Old components
import TripDocuments from '../../../components/cart/TripDocuments';

import ItemLayout from '../ItemLayout';
import AirlineItem from '../AirlineItem';
import AeroexpressItem from '../AeroexpressItem';
import HotelItem from '../HotelItem';
import TransferItem from '../TransferItem';
import TrainItem from '../TrainItem';
import { CustomItem } from '../CustomItem';
import { EventItem } from '../EventItem';
import AnalyticsSelect from '../AnalyticsSelect';
import { TripTags } from '../TripTags';
import FlightCertificateItem from '../FlightCertificateItem';
import { TaxiVoucherItem } from '../TaxiVoucheriItem';
import { TaxiItem } from '../TaxiItem';
import { PriceBalance } from '../PriceBalance';
import VipHallItem from '../VipHallItem';
import { InsuranceItem } from '../InsuranceItem';

import { OrderHeader } from './components/OrderHeader';
import { HistoryChange } from './components/HistoryChange';
import { AdditionalServicesItems } from './components/AdditionalServices';
import { TripItemEmployees } from './components/TripItemEmployees';
import { FreeFlightCertificate } from './components/FreeFlightCertificate';
import { AdditionalServiceItem } from '../AdditionalServiceItem';
import TaxiDetailsPrice from '../TaxiDetailsPrice';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { ACCOUNT_IM } from '../../bi/constants/accounts';
import getAccountId from '../../bi/utils/getAccountId';

import PopupsService from '../../bi/services/popups';
import Favorite from '../../bi/services/favorites';
import Hotels from '../../bi/services/hotels';
import { ITripItem } from '../../bi/types/trips';
import { ITripTagsService } from '../../bi/types/tripTags';
import NotificationService from '../../bi/services/notification';
import AccountSettingsService from '../../bi/services/accountSettings';
import OrderService from '../../bi/services/order';
import WorkspaceService from '../../bi/services/workspace';

import { UiLanguageType } from '../../bi/services/uiSettings/types';
import { ItemDepartments } from '../../bi/services/departments/types';

import styles from './index.module.css';

interface ITripItemProps {
  tripId: number,
  tripName: string,
  aggregationId: number,
  item: ITripItem,
  status: string,
  tripTagsService: ITripTagsService,
  companies: ItemDepartments[],
  projects: any[],
  messageSent: any,
  onSendDocument?: (type: string, tripItemId: number, employeeId: number) => void,
  onPrintDocument?: (tripItemId: number, employeeId: number, type: string) => void,
  onDownloadVoucherLocale?: (tripItemId: number, locale: UiLanguageType) => void,
  onCancelService: () => void,
  onCalculationCancelService: () => void,
  onViewEditHistory?: () => void,
  onEditService: () => void,
  hasDisabledUser?: boolean,
  workspaceService: WorkspaceService,
  orderService: OrderService,
  hotelsService: Hotels,
  favoritesService: Favorite,
  notificationService: NotificationService,
  accountSettingsService: AccountSettingsService,
  popupsService: PopupsService,
  tripsRequests: any[],
  onOpenApproveDialog: () => void,
  airTicketHasCertificate: boolean,
  flightCertificateStatus: string,
  customAnalytics: any[],
  setCustomAnalytics: (tripId: number, analyticsValueId: number, itemId: number) => void,
  unsetCustomAnalytics: (tripId: number, analyticsId: number, itemId: number) => void,
  prohibitionVouchers: any[],
  vipHallRef: any | undefined,
  onAddAnalyticsValue: (aId: number, aValue: string, tripId: number, itemId: number) => void,
  qaAttr?: string,
}

interface ITripItemState {
  historyOpened: boolean,
  loading: boolean,
  selectedTripTags: any[],
  currentTripTags: any[],
  itemData: any,
  showElem: boolean,
}

const LABELS = {
  HIDE_HISTORY: getText('components:tripItem.hideHistory'),
  HISTORY: getText('components:tripItem.history'),
  CANCELLED: getText('components:tripItem.cancelled'),
  SELECTED_SEAT: getText('components:tripItem.selectedSeat'),
  SELECTED_SEATS: getText('components:tripItem.selectedSeats'),
  NO_VOUCHER_TRIPS: getText('components:tripItem.noVoucherTrips'),
  VOUCHER_TRIPS: getText('components:tripItem.voucherTrips'),
  ADD_TAX: getText('hotels:hotelResult.item.rate.andTax'),
};

class TripItem extends Component<ITripItemProps, ITripItemState> {
  constructor(props: ITripItemProps) {
    super(props);

    const {
      item: {
        Tags,
        ActualVersion: { JsonData },
      },
    } = props;
    const selectedTripTags = Tags ? Tags.map(({ Id }) => Id) : [];

    this.state = {
      itemData: JSON.parse(JsonData),
      historyOpened: false,
      loading: false,
      selectedTripTags,
      currentTripTags: selectedTripTags,
      showElem: true,
    };

    this.setShowElem = this.setShowElem.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    const { JsonData } = item.ActualVersion;
    const data = JSON.parse(JsonData);

    if (data.AutoRefundInfo) {
      this.hendleShowElem();
    }
  }

  hendleShowElem = () => {
    const { item } = this.props;
    const { JsonData } = item.ActualVersion;
    const data = JSON.parse(JsonData);

    if (data.AutoRefundInfo.Status === 3) {
      this.setState({ showElem: false });
    } else {
      this.setState({ showElem: true });
    }

    return null;
  };

  setShowElem = (value: boolean) => {
    this.setState({ showElem: value });
  };

  static getDerivedStateFromProps(nextProps: ITripItemProps) {
    return {
      itemData: JSON.parse(nextProps.item.ActualVersion.JsonData),
      flightCertificateStatus: nextProps.flightCertificateStatus,
    };
  }

  handleSwitchHistory = () => {
    const { onViewEditHistory } = this.props;

    this.setState(
      {
        historyOpened: !this.state.historyOpened,
      },
      () => {
        if (this.state.historyOpened && onViewEditHistory) {
          onViewEditHistory();
        }
      },
    );
  };

  handleUpdateTripTags = (tags: any) => {
    const {
      tripTagsService,
      item: { Id },
    } = this.props;
    const { currentTripTags } = this.state;

    const isSameArrays =
      tags.length === currentTripTags.length &&
      tags.every((item: any) => currentTripTags.includes(item));

    if (!isSameArrays) {
      this.setState({ loading: true });

      return tripTagsService
        .updateTripItemTripTags(Id, tags)
        .then(() =>
          this.setState({
            loading: false,
            currentTripTags: tags,
          }),
        );
    }

    return () => {};
  };

  handleSendAnalyticsOnVoucher = (serviceType: string) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_VOUCHER_PRESSED(this.props.tripId, ANALYTIC_SERVICE_TYPE_MATCH[serviceType]),
    );
  };

  handleChangeSelectedTripTags = (selectedTripTags: any) =>
    this.setState({ selectedTripTags });

  getFreeSeatsData = () => {
    const {
      itemData: { AdditionalServices = [] },
    } = this.state;

    const freeSeatsGroupedBySegmentId = AdditionalServices.filter(
      ({ Type, Service }: any) =>
        Type === ADDITIONAL_SERVICES.Seat && Service.Price !== 0,
    );

    if (!freeSeatsGroupedBySegmentId.length) {
      return {
        caption: null,
        data: null,
      };
    }

    const hasOneSegmentWithOneSeat =
      freeSeatsGroupedBySegmentId.length === 1;

    const label = hasOneSegmentWithOneSeat
      ? LABELS.SELECTED_SEAT
      : LABELS.SELECTED_SEATS;

    const freeSeatsAsString = freeSeatsGroupedBySegmentId
      .map(({ Service: { Number } }: any) => Number).join(', ');

    return {
      caption: label,
      data: freeSeatsAsString,
    };
  };

  setCustomAnalytics = async ({ Id: analyticsValueId }: any) => {
    const {
      tripId,
      setCustomAnalytics,
      item: { Id: itemId },
    } = this.props;

    this.setState({
      loading: true,
    });

    await setCustomAnalytics(tripId, analyticsValueId, itemId);

    this.setState({
      loading: false,
    });
  };

  unsetCustomAnalytics = async (analyticsId: any) => {
    const {
      tripId,
      unsetCustomAnalytics,
      item: { Id: itemId },
    } = this.props;

    this.setState({
      loading: true,
    });

    await unsetCustomAnalytics(tripId, analyticsId, itemId);

    this.setState({
      loading: false,
    });
  };

  renderItem = () => {
    const { itemData }: any = this.state;
    const {
      status,
      hotelsService,
      workspaceService,
      notificationService,
      favoritesService,
      item: {
        ActualVersion: { Status, ServiceType },
      },
      aggregationId,
    } = this.props;

    switch (ServiceType) {
      case SERVICETYPE.AIR: {
        return itemData.Routes.map((route: any, index: number) => (
          <div key={ `airline_item_${guid()}_${index}` } className={ styles['item-airline'] }>
            <AirlineItem fromTrip showSegments route={ route } meta={ itemData } getFreeSeatsData={ this.getFreeSeatsData } />
          </div>
        ));
      }

      case SERVICETYPE.HOTEL: {
        const { item: { ActualVersion: { AdditionalData } } } = this.props;
        const {
          CheckinDate,
          CheckoutDate,
          Room: { Category, FreeCancellation },
          Hotel: { Name, City, Address, Stars },
        } = itemData;

        const item: any = {
          checkin: CheckinDate,
          checkout: CheckoutDate,
          room: {
            Name: Category,
            Amenities: {
              CancellationInfo: FreeCancellation,
            },
          },
          hotel: {
            Name,
            City,
            Address,
            Stars,
          },
          raw: itemData,
        };

        return (
          <HotelItem
            item={ item }
            fromTrip
            hotelItemFullInfo
            additionalData={ AdditionalData }
            aggregationId={ aggregationId }
            hotelsService={ hotelsService }
            notificationService={ notificationService }
            workspaceService={ workspaceService }
            favoritesService={ favoritesService }
          />
        );
      }

      case SERVICETYPE.TRANSFER: {
        return (
          <TransferItem
            item={ itemData }
          />
        );
      }

      case SERVICETYPE.TRAIN: {
        return (
          <TrainItem
            item={ itemData }
            isCancellation={ canCancelledTrip(status, Status) }
          />
        );
      }

      case SERVICETYPE.CUSTOM: {
        return <CustomItem item={ itemData } />;
      }

      case SERVICETYPE.EVENT: {
        return <EventItem item={ itemData } />;
      }

      case SERVICETYPE.AEROEXPRESS: {
        const {
          Trip: {
            TariffType,
            DepartureDate,
            BackwardDate,
            Direction,
            Airport,
          },
        }: any = itemData;
        const mappedItemData = {
          ...itemData,
          Trip: {
            tariff_type: TariffType,
            departure_date: DepartureDate,
            backward_date: BackwardDate,
            direction: Direction,
            airport: Airport,
          },
        };

        return <AeroexpressItem item={ mappedItemData } />;
      }

      case SERVICETYPE.FLIGHT_CERTIFICATE: {
        return <FlightCertificateItem meta={ itemData } />;
      }

      case SERVICETYPE.TAXI_VOUCHER: {
        return <TaxiVoucherItem item={ itemData.ReserveData } />;
      }

      case SERVICETYPE.TAXI: {
        return <TaxiItem item={ itemData } />;
      }

      case SERVICETYPE.VIP_HALL: {
        return <VipHallItem item={ itemData } />;
      }

      case SERVICETYPE.AIR_ADDITIONAL_SERVICE: {
        return <AdditionalServiceItem item={ itemData } />;
      }
      case SERVICETYPE.INSURANCE: {
        return <InsuranceItem item={ itemData } />;
      }
    }

    return null;
  };

  renderHeaderItem = () => {
    const { itemData } = this.state;
    const {
      status,
      tripId,
      item,
      item: {
        ActualVersion: { ServiceType, CompanyId },
      },
      hasDisabledUser,
      messageSent,
      airTicketHasCertificate,
      workspaceService: {
        canBuyPersonalUnlimited,
        isAdmin,
        rights,
        travelPolicy,
      },
      accountSettingsService: {
        getSalary1C,
        getNoChangeOrReturnTrip,
        getCompanySettings,
        getAccountSettings,
        store: {
          accountSettings: {
            // @ts-ignore
            accessFlightCertificates,
          },
        },
      },
      onCancelService,
      onCalculationCancelService,
      tripsRequests,
      onEditService,
      onOpenApproveDialog,
      popupsService,
      tripName,
    } = this.props;

    if (ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE || ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE) {
      return null;
    }

    const companiesSettings: CompanySetting[] = getCompanySettings();
    const removeFlightCertificate = getTransformationCompanySettings({
      companiesSettings,
      CompanyId,
      setting:
      SettingsType.removeFlightCertificate,
    });
    const isFreeFlightCertificate = getFreeFlightCertificate(companiesSettings, CompanyId);

    return (
      <OrderHeader
        item={ item }
        tripId={ tripId }
        itemData={ itemData }
        tripName={ tripName }
        messageSent={ messageSent }
        hasDisabledUser={ !!hasDisabledUser }
        airTicketHasCertificate={ airTicketHasCertificate }
        canBuyPersonalUnlimited={ canBuyPersonalUnlimited }
        getSalary1C={ getSalary1C() }
        accessFlightCertificates={ accessFlightCertificates }
        removeFlightCertificate={ removeFlightCertificate }
        status={ status }
        onEditService={ onEditService }
        onCancelService={ onCancelService }
        onCalculationCancelService={ onCalculationCancelService }
        popupsService={ popupsService }
        onOpenApproveDialog={ onOpenApproveDialog }
        isAdmin={ isAdmin }
        rights={ rights }
        getNoChangeOrReturnTrip={ getNoChangeOrReturnTrip() }
        isFreeFlightCertificate={ isFreeFlightCertificate }
        tripsRequests={ tripsRequests }
        travelPolicy={ travelPolicy }
        accountSettings={ getAccountSettings() }
        showElem={ this.state.showElem }
      />
    );
  };

  renderTripTags = (readonly = false) => {
    const { tripTagsService, workspaceService } = this.props;
    const { selectedTripTags } = this.state;

    return (
      <TripTags
        noeditable={ readonly }
        tripTagsService={ tripTagsService }
        onUpdateTags={ this.handleUpdateTripTags }
        selectedTags={ selectedTripTags }
        onChangeTags={ this.handleChangeSelectedTripTags }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
        descriptionClassName={ styles.trip_tags_description }
      />
    );
  };

  renderAdditionalOldServices = () => {
    const {
      props: {
        item,
        companies,
        projects,
      },
      state: { itemData },
    } = this;

    if (item.ActualVersion.ServiceType !== SERVICETYPE.AIR) {
      return null;
    }

    return (
      <AdditionalServicesItems
        item={ item }
        companies={ companies }
        projects={ projects }
        itemData={ itemData }
        renderCustomAnalyticsAndTags={
          this.renderCustomAnalyticsAndTags
        }
      />
    );
  };

  renderCustomAnalytics = (readonly = false) => {
    const {
      tripId,
      customAnalytics = [],
      item: { UserAnalytics = [], Id: itemId },
      onAddAnalyticsValue,
    } = this.props;

    return customAnalytics.map(analytics => {
      const value = analytics.Values.find(({ Id }: any) =>
        UserAnalytics.includes(Id),
      );

      return (
        <div
          key={ analytics.Id }
          className={ styles.custom_analytics_and_tags_item }
        >
          <AnalyticsSelect
            withRequiredValidation
            readonly={ readonly }
            analytics={ analytics }
            value={ value }
            onSet={ this.setCustomAnalytics }
            onUnset={ this.unsetCustomAnalytics }
            onAdd={ (aId, aValue) => onAddAnalyticsValue(aId, aValue, tripId, itemId) }
            selectedClassName={ styles.custom_analytics_and_tags_item_select }
          />
        </div>
      );
    });
  };

  renderCustomAnalyticsAndTags = (disabled = false) => (
    <div className={ styles.custom_analytics_and_tags }>
      {this.renderCustomAnalytics(disabled)}
      <div className={ styles.custom_analytics_and_tags_item }>
        {this.renderTripTags(disabled)}
      </div>
    </div>
  );

  renderCustomPrice = () => {
    const { item: { ActualVersion: { ServiceType } } } = this.props;

    if (ServiceType !== SERVICETYPE.TAXI_VOUCHER) {
      return null;
    }

    const { itemData: { Voucher: { BeginAmount, CurrentAmount } } }: any = this.state;

    return <PriceBalance currentAmount={ CurrentAmount } beginAmount={ BeginAmount } />;
  };

  renderVatHtml = () => {
    const { PriceDetails: { HasVAT } } = JSON.parse(this.props.item.ActualVersion.JsonData);

    if (!HasVAT) return null;

    return (
      <div className={ styles.vat }>
        <Text className={ styles.label } type='NORMAL_12' color='gray'>
          { LABELS.ADD_TAX }
        </Text>
      </div>
    );
  };

  renderOption = () => {
    const { item: { ActualVersion: { ServiceType } } } = this.props;

    if (ServiceType !== SERVICETYPE.HOTEL) return null;

    return this.renderVatHtml();
  };

  renderOperationPrice = (amount: number, vat: number) => {
    const { item: { ActualVersion: { ServiceType } } } = this.props;

    if (ServiceType !== SERVICETYPE.TAXI_VOUCHER) {
      return null;
    }

    const baseAmount = Number(amount) - Number(vat);

    return (
      <div className={ styles.block }>
        <TaxiDetailsPrice
          tariffAmount={ baseAmount }
          vatAmount={ vat }
        />
      </div>
    );
  };

  renderOperation = (item: any) => {
    const { Id, VoucherId, Amount, Vat } = item;

    return (
      <ItemPanel
        key={ `${VoucherId}_${Id}` }
        renderHeader={ () => null }
        className={ styles.wrapper }
      >
        <div className={ styles.body }>
          <ItemLayout
            price={ Amount }
            serviceType={ SERVICETYPE.TAXI_VOUCHER }
            renderOption={ () => this.renderOperationPrice(Amount, Vat) }
          >
            <TaxiItem item={ item } />
          </ItemLayout>
          <div className={ styles.actions }>
            { this.renderCustomAnalyticsAndTags(true) }
          </div>
        </div>
      </ItemPanel>
    );
  };

  renderOperations = () => {
    const { item: { ActualVersion: { ServiceType } } } = this.props;

    if (ServiceType !== SERVICETYPE.TAXI_VOUCHER) {
      return null;
    }

    const { itemData: { Voucher: { Operations } } }: any = this.state;

    const content = !Operations.length ? (
      <Text type='bold_22' color='gray' className={ styles.title_operations }>
        {LABELS.NO_VOUCHER_TRIPS}
      </Text>
    ) : (
      <>
        <Text type='bold_22' color='gray' className={ styles.title_operations }>
          {LABELS.VOUCHER_TRIPS}
        </Text>
        { Operations.map((item: any) => this.renderOperation(item)) }
      </>
    );

    return (
      <div className={ styles.operations_wrapper }>
        { content }
      </div>
    );
  };

  renderFlightCertificate = () => {
    const {
      orderService: { downloadFlightCertificate },
      accountSettingsService: { getCompanySettings },
      item: {
        ActualVersion: { CompanyId, ServiceType, JsonData },
        Id,
      },
      flightCertificateStatus,
    } = this.props;

    const itemData = parseJsonString(JsonData);

    const companiesSettings = getCompanySettings();
    const isFreeFlightCertificate = getFreeFlightCertificate(companiesSettings, CompanyId);

    if (ServiceType !== SERVICETYPE.AIR || !isFreeFlightCertificate) return null;

    return (
      <FreeFlightCertificate
        status={ flightCertificateStatus }
        itemId={ Id }
        downloadFlightCertificate={ downloadFlightCertificate }
        itemData={ itemData }
      />
    );
  };

  renderTransferViewers = () => {
    const { item: { ActualVersion: { ServiceType } } } = this.props;

    if (ServiceType !== SERVICETYPE.TRANSFER) return null;

    const { itemData: { Viewers } } = this.state;

    if (!Viewers) return null;

    const viewersListHtml = Viewers.map((number: number, idx: number) => (
      <div key={ `${styles.viewer}_${idx}` } className={ styles.viewer }>
        {number}
      </div>
    ));

    return (
      <div className={ styles.viewers }>{ viewersListHtml }</div>
    );
  };

  render() {
    const {
      item: {
        ActualVersion,
        ActualVersion: {
          TripItemId,
        },
        AgentFee,
        Versions,
        Id,
      },
      companies,
      projects,
      status,
      onPrintDocument,
      onSendDocument,
      onDownloadVoucherLocale,
      flightCertificateStatus,
      accountSettingsService: {
        getHowEmployeeDisplay,
        getCartEmployeeBirthday,
      },
      prohibitionVouchers,
      popupsService: {
        store: {
          vipHallPopup,
        },
      },
      orderService: {
        setAdditionalFeeSmartagentTrip,
      },
      vipHallRef,
      tripId,
      qaAttr,
    } = this.props;
    const {
      historyOpened,
      loading,
      itemData,
      itemData: { AdditionalServices = [], PriceDetails = null, Name, ProviderName },
      showElem,
    } = this.state;

    const isBookedTrip = status === TRIPSTATUS.BOOKED;
    const isShowAdditionalFee = status === TRIPSTATUS.COMPLETED;

    const { Price, EventDate, ServiceType, CompanyId } = ActualVersion;
    const isProhibitionVoucher = prohibitionVouchers.some(id => id === CompanyId);
    const additionalServicesPrice = AdditionalServices.reduce((acc: any, service: any) => {
      if (service.Type !== ADDITIONAL_SERVICES.Seat || !service.EmdNumber) {
        return acc;
      }

      return acc + service.Service.Price;
    }, 0);
    const price = Price ? Price - additionalServicesPrice : null;
    const historyHtml = status !== TRIPSTATUS.CANCELLED &&
      historyOpened && <HistoryChange items={ Versions } />;

    const canceledHtml = status === TRIPSTATUS.CANCELLED && (
      <Text className={ styles['cancellation-date'] }>
        {LABELS.CANCELLED} {parseUnix(EventDate).format('ll HH:mm')}
      </Text>
    );

    const { ReferralLink: referralLink = null } = itemData;

    const renderDocument = () => {
      const accountId = getAccountId();

      if (ServiceType === SERVICETYPE.TRAIN && !itemData.TicketId && accountId === ACCOUNT_IM) return null;

      return (
        <TripDocuments
          id={ Id }
          item={ ActualVersion }
          isProhibitionVoucher={ isProhibitionVoucher }
          flightCertificateStatus={ flightCertificateStatus }
          onPrintDocument={ onPrintDocument }
          onSendDocument={ onSendDocument }
          onDownloadVoucherLocale={ onDownloadVoucherLocale }
          onDownloadVoucher={ this.handleSendAnalyticsOnVoucher }
          tripId={ tripId }
          status={ status }
        />
      );
    };

    const documentHtml = showElem === false
      ? <DotLoading/>
      : renderDocument();

    const checkAvailabilityAdditionalServices =
      (ProviderName === AIRLINE_PROVIDERS.S7 || ProviderName === AIRLINE_PROVIDERS.MIXVEL)
      && (!PriceDetails || !PriceDetails.Total)
      && Name ?
        null :
        documentHtml;

    const versions = Versions.filter(
      (version, index) =>
        !Versions[index + 1] ||
        version.Price !== Versions[index + 1].Price,
    );

    const renderHistoryChange = () => {
      const isShowHist = status !== TRIPSTATUS.CANCELLED &&
      versions.length > 1;

      const accountId = getAccountId();

      if (ServiceType === SERVICETYPE.TRAIN && !itemData.TicketId && accountId === ACCOUNT_IM) return null;

      return isShowHist ? (
        <Button
          type='textual'
          className={ styles.history }
          onClick={ this.handleSwitchHistory }
        >
          {historyOpened ? LABELS.HIDE_HISTORY : LABELS.HISTORY}
        </Button>
      ) : null;
    };

    const operationsHtml = this.renderOperations();
    const displayEmployeeAccountSettings = getHowEmployeeDisplay();
    const cartEmployeeBirthday = getCartEmployeeBirthday();
    const disabled = ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE;

    return (
      <>
        <ItemPanel
          ref={ vipHallPopup.show ? vipHallRef : undefined }
          className={ styles.wrapper }
          renderHeader={ this.renderHeaderItem }
          loading={ loading }
          qaAttr={ qaAttr }
        >
          <div className={ styles.body }>
            <ItemLayout
              price={ price }
              serviceType={ ServiceType }
              renderCustomPrice={ this.renderCustomPrice }
              feeSmartagent={ AgentFee }
              setAdditionalFeeSmartagent={ setAdditionalFeeSmartagentTrip }
              isAdditionalFee={ isBookedTrip }
              isOnlyReadingAdditionalFee={ isShowAdditionalFee }
              itemId={ TripItemId }
              isTrip={ isBookedTrip }
              versionStatus={ ActualVersion.Status }
              referralLink={ referralLink }
              status={ status }
              qaAttrPrice={ QA_ATTRIBUTES.trips.trip.price }
              renderOption={ this.renderOption }
            >
              { this.renderItem() }
              { canceledHtml }
            </ItemLayout>
            <div className={ styles.actions }>
              <div className={ styles.info }>
                <div className={ styles.employees }>
                  <TripItemEmployees
                    currentVersion={ ActualVersion }
                    companies={ companies }
                    projects={ projects }
                    displayEmployee={ displayEmployeeAccountSettings }
                    cartEmployeeBirthday={ cartEmployeeBirthday }
                  />
                </div>
                <div className={ styles.action_download }>
                  { checkAvailabilityAdditionalServices }
                  { this.renderFlightCertificate() }
                  { renderHistoryChange() }
                </div>
              </div>
              { this.renderTransferViewers() }
              { historyHtml }
              { this.renderCustomAnalyticsAndTags(disabled) }
            </div>
          </div>
        </ItemPanel>
        {this.renderAdditionalOldServices()}
        {operationsHtml}
      </>
    );
  }
}

export default TripItem;
