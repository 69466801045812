import { observable, computed, action, makeObservable } from 'mobx';

import {
  POPUP_POSITIONS,
  POPUP_STYLE_TYPES,
  POPUP_GROUP_TYPES,
} from '../../constants/popups';

import { PopupItem, VipHallPopup } from './types';

const getDefaultPopupSettings = () => ({
  show: false,
  title: '',
  description: '',
  submit: null,
  cancel: null,
  styles: POPUP_STYLE_TYPES.DEFAULT,
  position: POPUP_POSITIONS.TOP_RIGHT,
  closing: false, // utility property for hide animation
  group: POPUP_GROUP_TYPES.DEFAULT,
  image: null,
});

const getUuid = () => Date.now() + ((Math.random() * Math.random()) / Math.random());

export interface IPopupsStore {
  items: { [key: number]: PopupItem },
  popupsState: { [key: string]: boolean },
  vipHallPopup: VipHallPopup,
  showTravelPopup: boolean,
  showAnalyticsPopup: boolean,
  showTripPlanPopup: boolean,
  showSmartagentPopup: boolean,
  add_trip_item: boolean,
  add_trip_item_in_cart: boolean,
  surveyForm: boolean,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable items: { [key: number]: PopupItem } = {};
  @observable popupsState: { [key: string]: boolean } = {};
  @observable vipHallPopup: VipHallPopup = {
    show: false,
    id: null,
  };
  @observable showTravelPopup = true;
  @observable showAnalyticsPopup = true;
  @observable showTripPlanPopup = true;
  @observable showTripItemPopup = true;
  @observable showSmartagentPopup = true;

  @action
  setShowVipHallPopup = (value: VipHallPopup) => {
    this.vipHallPopup = value;
  };

  @computed
  get popups() {
    return Object.keys(this.items).map(Number).reduce<(PopupItem & { key?: number })[]>((result, k) => {
      const newValue = [...result];
      const item = this.items[k];

      if (item.show) {
        newValue.push({ ...item, key: k });
      }

      return newValue;
    }, []);
  }

  @action
  closeSinglePopup = (uuid: number) => {
    this.updatePopup(uuid, { closing: true });
    setTimeout(() => this.updatePopup(uuid, { show: false }), 1000);
  };

  @action
  clearPopup = () => {
    this.items = {};
  };

  @action
  setPopupsState = (popups: { [key: string]: boolean }) => {
    this.popupsState = popups;
  };

  @action
  updatePopoutState = (name: string) => {
    this.popupsState = {
      ...this.popupsState,
      [name]: true,
    };
  };

  @action
  addPopup = (payload: PopupItem) => {
    const uuid = getUuid();
    const sAction = payload.submit?.action;
    const cAction = payload.cancel?.action;

    this.items = {
      ...this.items,
      [uuid]: {
        ...getDefaultPopupSettings(),
        ...payload,
        submit: {
          ...payload.submit,
          action: () => {
            sAction?.();
            this.closeSinglePopup(uuid);
          },
        },
        cancel: {
          ...payload.cancel,
          action: () => {
            cAction?.();
            this.closeSinglePopup(uuid);
          },
        },
      },
    };

    return uuid;
  };

  @action
  updatePopup = (id: number, payload: { [key: string]: boolean }) => {
    this.items = {
      ...this.items,
      [id]: { ...this.items[id], ...payload },
    };
  };

  @action
  closePopupsByType = (exclude: string) => {
    const idsToBeClosed = Object.keys(this.items).map(Number).filter((k) => {
      const itemGroup = this.items[k].group;

      return itemGroup === exclude;
    });

    idsToBeClosed.forEach(id => this.closeSinglePopup(Number(id)));
  };

  @action
  setShowTravelPopup = (value: boolean) => {
    this.showTravelPopup = value;
  };

  @action
  setShowAnalyticsPopup = (value: boolean) => {
    this.showAnalyticsPopup = value;
  };

  @action
  setShowTripPlanPopup = (value: boolean) => {
    this.showTripPlanPopup = value;
  };

  @action
  setShowSmartagentPopup = (value: boolean) => {
    this.showSmartagentPopup = value;
  };

  @action
  setShowTripItemPopup = (value: boolean) => {
    this.showTripItemPopup = value;
  };
}

const PopupsStore = new Store();

export { PopupsStore, Store as PopupsStoreType };
