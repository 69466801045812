// @ts-nocheck
import React from 'react';
import { Text, PROPS } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import parseJsonString from '../../../bi/utils/parseJsonString';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';

import styles from './styles/index.module.css';

interface TripTrainTypeProps {
  item: any,
  travellers: string | null | React.JSX.Element,
  serviceType: string,
}

const TripTrain = (item, travellers, serviceType): TripTrainTypeProps => {
  const { jsonData, id } = item;
  const data = parseJsonString(jsonData);

  if (!data) return null;

  const {
    DateDepartureLocal,
    DateArrivalLocal,
    StationDepart,
    StationArrive,
    TrainNumber,
    CarTypeDescription,
  } = data;

  const date = formatRangeDateWithSimplicity(DateDepartureLocal, DateArrivalLocal, { withTime: true });

  return (
    <div key={ `trip_train_${id}` } className={ styles.wrapper }>
      <Text qaAttr={ QA_ATTRIBUTES.cart.duplicate.date }>{date},</Text>
      <Text
        className={ styles.capitalize }
        type={ PROPS.TEXT.TYPES.NORMAL_14_130 }
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.info }
      >
        {serviceType}
        &nbsp;
        {StationDepart}
        &nbsp;
        {StationArrive},
        &nbsp;
        {TrainNumber},
        &nbsp;
        {CarTypeDescription},
      </Text>
      <Text
        type={ PROPS.TEXT.TYPES.NORMAL_14 }
        color={ PROPS.TEXT.COLORS.GRAY }
        qaAttr={ QA_ATTRIBUTES.cart.duplicate.travellers }
      >
        {travellers}
      </Text>
    </div>
  );
};

export default TripTrain;
