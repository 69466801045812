import { getText } from '../../../i18n';

const POPUP_POSITIONS = {
  TOP_RIGHT: 'top-right',
  TOP_RIGHT_BELOW: 'top-right-below',
  TOP_RIGHT_ABOVE: 'top-right-above',
};

const POPUP_STYLE_TYPES = {
  DEFAULT: 'default',
  CLICKABLE: 'clickable',
};

const POPUP_GROUP_TYPES = {
  SEARCH_RESULT: 'SEARCH_RESULT',
  SHOWCASE: 'SHOWCASE',
  DEFAULT: 'default',
};

const POPUP_TYPES = {
  AEROEXPRESS: 'AEROEXPRESS',
  TRAVEL_POLICIES: 'TRAVEL_POLICIES',
  VIP_HALL: 'VIP_HALL',
  VIP_HALL_INFO: 'VIP_HALL_INFO',
  ADD_TRIP_ITEM: 'ADD_TRIP_ITEM',
  ADD_TRIP_ITEM_IN_CART: 'ADD_TRIP_ITEM_IN_CART',
  ADD_TRIP_PLAN: 'TRIP_PLAN',
  SMARTAGENT: 'SMARTAGENT',
};

const POPUP_NAME = {
  VIPHALL_POPUP: 'viphall_popup',
  ADDING_ORDER_TRIP_PLAN: 'trip_plan_popup',
  ADDING_ORDER: 'add_trip_item_popup',
  ADDING_ORDER_IN_CARD: 'add_trip_item_in_cart_popup',
  SMARTAGENT: 'smartagent_popup',
  CONTEXT: {
    AVIA: 'mice_air_group_search',
    TRAIN: 'mice_train_group_search',
    HOTEL_REGION: 'mice_hotels_region_group_search',
    HOTEL: 'mice_hotels_hotel_group_search',
  },
};

const LABELS = {
  AEROEXPRESS: {
    TITLE: getText('services:popups.aeroexpress.title'),
    DESCRIPTION: (dates: string) => getText('services:popups.aeroexpress.description', { dates }),
    SUBMIT: getText('services:popups.aeroexpress.submit'),
    CANCEL: getText('services:popups.aeroexpress.cancel'),
  },
  TRAVEL_POLICIES: {
    FIRST: {
      TITLE: getText('services:popups.travelPolicy.first.title'),
      SUB_TITLE: getText('services:popups.travelPolicy.first.subTitle'),
      DESCRIPTION: getText('services:popups.travelPolicy.first.description'),
      SUBMIT: getText('services:popups.travelPolicy.first.submit'),
      CANCEL: getText('services:popups.travelPolicy.first.cancel'),
    },
    SECOND: {
      TITLE: getText('services:popups.travelPolicy.second.title'),
      DESCRIPTION: getText('services:popups.travelPolicy.second.description'),
      SUBMIT: getText('services:popups.travelPolicy.second.submit'),
      CANCEL: getText('services:popups.travelPolicy.second.cancel'),
    },
  },
  VIP_HALLS: {
    TITLE: getText('services:popups.vipHall.title'),
    DESCRIPTION: getText('services:popups.vipHall.description'),
    SUBMIT: getText('services:popups.vipHall.submit'),
    CANCEL: getText('services:popups.vipHall.cancel'),
  },
  VIP_HALLS_INFO: {
    TITLE: getText('services:popups.vipHallInfo.title'),
    DESCRIPTION: getText('services:popups.vipHallInfo.description'),
    SUBMIT: getText('services:popups.vipHallInfo.submit'),
    CANCEL: getText('services:popups.vipHallInfo.cancel'),
  },
  ADDING_ORDER: {
    TITLE: getText('services:popups.adding_order.title'),
    DESCRIPTION: getText('services:popups.adding_order.description'),
    SUBMIT: getText('services:popups.adding_order.submit'),
    CANCEL: getText('services:popups.adding_order.cancel'),
  },
  ADDING_TRIP_PLAN: {
    TITLE: getText('services:popups.adding_trip_plan.title'),
    DESCRIPTION: getText('services:popups.adding_trip_plan.description'),
    SUBMIT: getText('services:popups.adding_trip_plan.submit'),
    CANCEL: getText('services:popups.adding_trip_plan.cancel'),
  },
  SMARTAGENT: {
    LABEL: 'Пройдите опрос, чтобы сделать наш сервис еще лучше ',
    BODY: 'Опрос займет не больше минуты',
    GO: 'Перейти',
    CANCEL: 'Отмена',
  },
  SHOW_ERROR: getText('services:popups.showError'),
};

const POPUPS_KIND = {
  TRAVEL_POLICIES_FIRST_RUN: 'travel_policy_questionnaire_popup',
  TRAVEL_POLICIES_SECOND_RUN: 'travel_policy_ready_made_popup',
};

export {
  POPUP_POSITIONS,
  POPUP_STYLE_TYPES,
  POPUP_GROUP_TYPES,
  POPUP_TYPES,
  LABELS,
  POPUPS_KIND,
  POPUP_NAME,
};
